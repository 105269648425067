import { Button } from "primereact/button";
import React, { useState } from "react";
import ProfileCard from "./components/ProfileCard";
import ProfileDescription from "./components/ProfileDescription";
import { EditProfile } from "./components/EditProfileDialog";
import _defaultTo from "lodash/defaultTo";
import {useGetProfilesUsername} from "../lib/service/user-profile-resource";
const ProfilePage = () => {
  const [visible, setVisible] = useState(false);

  const { data, isLoading, error, isSuccess } = useGetProfilesUsername(
    _defaultTo(localStorage.getItem("loginUsername"), ""),
    {
      query: {
        queryKey: ["username", localStorage.getItem("loginUsername")],
      },
    }
  );

  return (
    <div className={"flex w-full overflow-y-auto justify-content-center gap-8"}>
      <div className={"flex flex-column h-30"}>
        <div className="flex justify-content-start align-items-center -mt-2 gap-2">
          <h1 className="text-5xl text-primary">Profile</h1>
          <Button
            icon="pi pi-pencil"
            rounded
            text
            raised
            onClick={() => setVisible(!visible)}
          ></Button>
        </div>
        <ProfileCard
          teams={data?.teams === undefined ? [] : data.teams}
          name={
            (data?.firstName === undefined ? "First name" : data?.firstName) +
            " " +
            (data?.lastName === undefined ? "Last name" : data?.lastName)
          }
        />
      </div>
      <div className="w-6 mt-8">
        <ProfileDescription data={data} />
      </div>
      {isSuccess && (
        <EditProfile
          visible={visible}
          initialData={data}
          setVisible={setVisible}
          handleClick={() => console.log("hello")}
        />
      )}
    </div>
  );
};

export default ProfilePage;
