import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

export const AXIOS_INSTANCE = Axios.create({ baseURL: process.env.REACT_APP_API_URL_COGNITO });

// Define a custom response type to include both data and status
export type ApiResponse<T> = {
  data: T;
  status: number;
  errorMessage: string;
};

// Modify the UseSGMutator function to return ApiResponse or AxiosError
export const UseSGMutatorIdentity = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<ApiResponse<T>> => {
  const source = Axios.CancelToken.source();

  const promise: Promise<ApiResponse<T>> = AXIOS_INSTANCE({
    ...config,
    ...options,
    cancelToken: source.token,
  })
    .then((response: AxiosResponse<T>) => {
      // Always return an ApiResponse, even for non-200 responses
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((error: AxiosError) => {
      // You can handle errors here if needed
      const errorMessage =
        (error.response?.data as { message?: string })?.message || 'Unknown error';
    
      return {
        errorMessage: errorMessage,
        status: error.response?.status || 500,
      };
    }) as Promise<ApiResponse<T>>;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};

 export type ErrorType<Error> = AxiosError<Error>;
 
 export type BodyType<BodyData> = BodyData;