import React, { useEffect, useState, useRef, ReactNode, ReactElement } from 'react';


interface RevealOnScrollProps {
    children: ReactNode;
    className?: string;
  }

const RevealOnScroll: React.FC<RevealOnScrollProps> = ({ children, className = '' }) => {
const revealRef = useRef<HTMLDivElement>(null);
const [isVisible, setIsVisible] = useState(false);

useEffect(() => {
    const observer = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => {
        if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
        }
        });
    },
    { threshold: 0.1 }
    );

    if (revealRef.current) {
    observer.observe(revealRef.current);
    }

    return () => {
    if (revealRef.current) {
        observer.unobserve(revealRef.current);
    }
    };
}, [revealRef]);

    return (
        <div ref={revealRef} className={`reveal ${isVisible ? 'active' : ''} ${className}`}>
        {children}
        </div>
    );
};

export default RevealOnScroll;