import React, { useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';

interface DatesProps {
    title: string;
    startDate: Date;
    endDate: Date;
    ageUpDate: Date;
    entryOpenDate: Date;
    onInputChange: (field: string, value: Date) => void;
  }

const Dates: React.FC<DatesProps> = ({title,
    startDate,
    endDate,
    ageUpDate,
    entryOpenDate,
    onInputChange,
}) => {

  return (
    <Card title={title} className="p-card">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', marginBottom: '1rem' }}>
          <div style={{ flex: '1', marginRight: '1rem' }}>
            <span className="p-float-label">
            <Calendar id="startDate" value={startDate} onChange={(e) => onInputChange('startDate', e.value!)} style={{ width: '100%' }} showIcon className="custom-calendar"/>
              <label htmlFor="startDate">Start date</label>
            </span>
          </div>

          <div style={{ flex: '1' }}>
            <span className="p-float-label">
            <Calendar id="endDate" value={endDate} onChange={(e) => onInputChange('endDate', e.value!)} style={{ width: '100%' }} showIcon className="custom-calendar"/>
              <label htmlFor="endDate">End date</label>
            </span>
          </div>
        </div>

        <div style={{ display: 'flex' }}>
          <div style={{ flex: '1', marginRight: '1rem' }}>
            <span className="p-float-label">
            <Calendar id="ageUpDate" value={ageUpDate} onChange={(e) => onInputChange('ageUpDate', e.value!)} style={{ width: '100%' }} showIcon className="custom-calendar"/>
              <label htmlFor="ageUpDate">Age up date</label>
            </span>
          </div>

          <div style={{ flex: '1' }}>
            <span className="p-float-label">
            <Calendar id="entryOpenDate" value={entryOpenDate} onChange={(e) => onInputChange('entryOpenDate', e.value!)} style={{ width: '100%' }} showIcon className="custom-calendar"/>
              <label htmlFor="entryOpenDate">Entry open date</label>
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Dates;