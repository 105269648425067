import { Chip } from "primereact/chip";
import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import {UserProfile} from "../../lib/model/backend-types";

interface HeaderChipProps {
  label: string;
}

interface ProfileProps {
  data?: UserProfile;
}

interface HeaderContentProps extends HeaderChipProps {
  content?: string;
}

const HeaderChip = ({ label }: HeaderChipProps) => {
  return (
    <Chip
      label={label}
      className="align-self-start min-w-min max-w-min"
      pt={{
        root: {
          style: {
            background: "#FECE02",
            borderBottomLeftRadius: "0px",
          },
        },
        label: {
          style: {
            fontWeight: 600,
            color: "#105F93",
            whiteSpace: "nowrap",
          },
        },
      }}
    />
  );
};

const HeaderContent = ({ label, content }: HeaderContentProps) => {
  return (
    <div className="border-bottom-1 custom-divider">
      <HeaderChip label={label} />
      <p className="font-medium">{content}</p>
    </div>
  );
};

const ProfileDescription = ({ data }: ProfileProps) => {
  return (
    <div>
      {data === undefined && (
        <div className="loading-overlay">
          <ProgressSpinner />
        </div>
      )}
      {data !== undefined && (
        <div className="flex flex-column gap-6 mt-4">
          <HeaderContent label="Biography" content={data.biography} />
          <HeaderContent label="Cell" content={data.phoneNumber} />
          <HeaderContent label="Email" content={data.primaryEmail} />
        </div>
      )}
    </div>
  );
};

export default ProfileDescription;
