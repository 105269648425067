import React from "react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useState } from "react";
import { postUsersSignup } from "../../lib/service/user-resource";
import "react-toastify/dist/ReactToastify.css";
import Axios, { AxiosError } from "axios";
import { showErrorToast } from "../../common/Toast";
import { PasswordValidation } from "./PasswordValidation";
import { ProgressSpinner } from "primereact/progressspinner";
import { TermsAndConditionsDialog } from "../../profile/components/TermsAndConditionsDialog";
import VerifyOtpDialog from "../../profile/components/VerifyOtpDialog";

interface UserRole {
  role: string;
}

export const SignupDialog = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [roleType, setRoleType] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [checked, setChecked] = useState<boolean | undefined>(false);
  const [selectedRole, setSelectedRole] = useState<UserRole | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [showOtp, setOtpVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const roles: UserRole[] = [
    { role: "Athlete" },
    { role: "Coach" },
    { role: "Parent" },
  ];

  const handleSignUp = async () => {
    try {
      setLoading(true);
      const signUpReq = {
        email,
        password,
        username,
      };

      const response = await postUsersSignup(signUpReq);

      if (response.status >= 400) {
        // Handle 4xx and 5xx errors
        showErrorToast(response.errorMessage);
      } else {
        // It's a successful response (ApiResponse<T>)
        localStorage.setItem("loginUsername", username);
        setOtpVisible(!showOtp);
      }
    } catch (error) {
      // Handle other types of errors
      console.error(error);
    }

    setLoading(false);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
  };

  return (
    <div>
      {loading && (
        <div className="loading-overlay">
          <ProgressSpinner />
        </div>
      )}
      <div className="flex flex-column align-items-center gap-1">
        <img src="/SG-logo.png" alt="image" width={179} height={150}></img>
        <div className="flex flex-column align-items-start w-8">
          <div className="align-self-start font-bold text-5xl">
            <p className="font-bold"> Sign up for fast results!</p>
          </div>
          <div className="flex gap-3 flex-column align-items-center w-12">
            <div
              className="flex flex-row align-items-start gap-2"
              style={{ width: "100%" }}
            >
              <InputText
                className="w-6"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Dropdown
                value={selectedRole}
                onChange={(e: DropdownChangeEvent) => setSelectedRole(e.value)}
                options={roles}
                optionLabel="role"
                placeholder="User role"
                className="w-6"
              />
            </div>
            <div
              className="flex flex-row align-items-start gap-2"
              style={{ width: "100%" }}
            >
              <InputText
                className="w-6"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <Password
                className="w-6"
                feedback={false}
                toggleMask
                placeholder="Password"
                value={password}
                onChange={(e) => handlePasswordChange(e)}
              />
            </div>
            <div className="align-self-start pl-1">
              <PasswordValidation password={password} />
            </div>
            <div className="card flex align-self-start pl-1">
              <Checkbox
                onChange={(e) => setChecked(e.checked)}
                checked={checked !== undefined ? checked : false}
              ></Checkbox>
              <div className="pl-2">
                {" "}
                I have read and accept the{" "}
                <span
                  className="text-primary"
                  onClick={() => setVisible(!visible)}
                >
                  Terms & Conditions
                </span>
              </div>
            </div>
            <Button
              className="w-8"
              label="Create account"
              size="large"
              onClick={handleSignUp}
              disabled={
                !checked ||
                password.length === 0 ||
                username.length === 0 ||
                email.length === 0 ||
                username.length === 0 ||
                selectedRole === null
              }
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <TermsAndConditionsDialog
          visible={visible}
          setVisible={setVisible}
          handleClick={() => console.log("hello")}
        />
        <VerifyOtpDialog
          visible={showOtp}
          username={username}
          setVisible={setOtpVisible}
          handleClick={() => console.log("hello")}
        />
      </div>
    </div>
  );
};

export default SignupDialog;
