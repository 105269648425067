import React from "react";

const Logout = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="p-avatar-menu-icon"
    >
      <path
        d="M9.75 15C10.1642 15 10.5 14.6642 10.5 14.25C10.5 13.8358 10.1642 13.5 9.75 13.5H3.5C2.39543 13.5 1.5 12.6046 1.5 11.5V3.5C1.5 2.39543 2.39543 1.5 3.5 1.5H9.75C10.1642 1.5 10.5 1.16421 10.5 0.75C10.5 0.335786 10.1642 0 9.75 0H3.5C1.567 0 0 1.567 0 3.5V11.5C0 13.433 1.567 15 3.5 15H9.75ZM10.7413 3.6989C11.0457 3.41795 11.5201 3.43693 11.8011 3.74129L14.8011 6.99129C15.0663 7.27859 15.0663 7.72142 14.8011 8.00871L11.8011 11.2587C11.5201 11.5631 11.0457 11.5821 10.7413 11.3011C10.4369 11.0202 10.4179 10.5457 10.6989 10.2413L12.537 8.25H4.75C4.33579 8.25 4 7.91422 4 7.5C4 7.08579 4.33579 6.75 4.75 6.75H12.537L10.6989 4.75871C10.4179 4.45435 10.4369 3.97985 10.7413 3.6989Z"
        fill="#105F93"
      />
    </svg>
  );
};

export default Logout;
