import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';

interface AwardsAndScoringProps {
    title: string;
    individualMaxScore: number;
    relayMaxScore: number;
    onlyARelaysScore: boolean;
    lastPlaceToScore: number;
    individualScoring: string[];
    lastPlaceToScoreRelay: number;
    relayScoring: string[];
    onInputChange: (field: string, value: string) => void;
    onNumberInputChange: (field: string, value: number | null) => void;
    onStateChange: (field: string, value: string) => void;
    onCheckBoxChange: (field: string, value: boolean) => void;
    onScoringChange: (newScoring: string[]) => void;
    onScoringChangeRelay: (newScoring: string[]) => void;
  }

  const AwardsAndScoring: React.FC<AwardsAndScoringProps> = ({
    title,
    individualMaxScore,
    relayMaxScore,
    onlyARelaysScore,
    lastPlaceToScore,
    individualScoring,
    lastPlaceToScoreRelay,
    relayScoring,
    onInputChange,
    onNumberInputChange,
    onStateChange,
    onCheckBoxChange,
    onScoringChange,
    onScoringChangeRelay
  })=> {
    const [lastPlace, setLastPlace] = useState<number>(1);
    const [lastPlaceRelay, setLastPlaceRelay] = useState<number>(1);


    useEffect(() => {
      generatePointsArray(lastPlace);
      generatePointsArrayRelay(lastPlaceRelay);
    }, [lastPlace, individualScoring, lastPlaceRelay, relayScoring]);
  
    const handleLastPlaceChange = (lastToScore: number) => {
      setLastPlace(lastToScore);
      generatePointsArray(lastToScore);
    };
  
    const handlePointsChange = (index: number, value: string) => {
        if (individualScoring && Array.isArray(individualScoring)) {
          const newArray = [...individualScoring];
          newArray[index] = value;
          onScoringChange(newArray);
        }
    };
  
    const generatePointsArray = (lastToScore: number) => {
        const newArray: string[] = [];
      
        if (individualScoring && individualScoring.length > 0) {
          for (let i = 1; i <= lastToScore; i++) {
            newArray.push(individualScoring[i - 1] || '');
          }
        }
      
        individualScoring = newArray;
    };   
    
    const handleLastPlaceChangeRelay = (lastToScore: number) => {
        setLastPlaceRelay(lastToScore);
        generatePointsArrayRelay(lastToScore);
      };
    
      const handlePointsChangeRelay = (index: number, value: string) => {
          if (relayScoring && Array.isArray(relayScoring)) {
            const newArray = [...relayScoring];
            newArray[index] = value;
            onScoringChangeRelay(newArray);
          }
      };
    
      const generatePointsArrayRelay = (lastToScore: number) => {
          const newArray: string[] = [];
        
          if (relayScoring && relayScoring.length > 0) {
            for (let i = 1; i <= lastToScore; i++) {
              newArray.push(relayScoring[i - 1] || '');
            }
          }
        
          relayScoring = newArray;
      }; 

  return (
    <Card title={title} >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex' }} className="mb-3">
                <div style={{ flex: '1', marginRight: '1rem' }}>
                    <span  className="p-float-label">
                    <InputNumber id="individualMaxScore" style={{ width: '100%' }}  value={individualMaxScore === undefined ? null : individualMaxScore} onValueChange={(e) => onNumberInputChange('individualMaxScore', e.value!)} />
                    <label htmlFor="individualMaxScore">Individual max score</label>
                    </span>
                </div>
                <div style={{ flex: '1', marginRight: '1rem' }}>
                    <span className="p-float-label">
                    <InputNumber id="relayMaxScore" style={{ width: '100%' }}  value={relayMaxScore === undefined ? null : relayMaxScore} onValueChange={(e) => onNumberInputChange('relayMaxScore', e.value!)} />
                        <label htmlFor="relayMaxScore">Relay max score</label>
                    </span>
                </div>
                <div style={{ flex: '1' }}>
                    <Checkbox id="onlyARelaysScore" onChange={e => onCheckBoxChange('onlyARelaysScore', e.checked!)} checked={onlyARelaysScore} style={{marginTop: '16px'}}></Checkbox>
                    <label htmlFor="onlyARelaysScore" className="ml-2">Only "A" relays score</label>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px' }}>
                <span style={{width: '220px'}}>Individual</span> <span>Score</span>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '700px' }} className="mb-2">
                <div style={{ flex: '1', marginRight: '1rem', maxWidth: '210px' }}>
                    <span className="p-float-label" style={{ maxWidth: '210px' }}>
                    <InputNumber
                        id="lastPlaceToScore"
                        style={{ maxWidth: '210px' }}
                        value={lastPlaceToScore === undefined ? null : lastPlaceToScore}
                        onValueChange={(e) => onNumberInputChange('lastPlaceToScore', e.value!)}
                        onChange={(e) => handleLastPlaceChange(e.value!)}
                    />
                    <label htmlFor="lastPlaceToScore">Last place to score</label>
                    </span>
                </div>
                <div style={{ display: 'flex', gap: '5px', flex: '1', flexWrap: 'wrap', maxWidth: '800px' }}>
                    {Array.from({ length: lastPlaceToScore }, (_, index) => (
                    <div key={index} style={{ width: '10.5%', marginBottom: '10px' }}>
                        <span className="p-float-label">
                        <InputText
                            id={`homeTeam${index}`}
                            style={{ width: '100%'}}
                            value={(individualScoring && individualScoring[index]) || ''}
                            onChange={(e) => handlePointsChange(index, e.target.value)}
                        />
                        <label htmlFor={`homeTeam${index}`}>{index + 1}.</label>
                        </span>
                    </div>
                    ))}
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px' }}>
                <span style={{width: '220px'}}>Relay</span> <span>Score</span>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '700px' }} className="mb-2">
                <div style={{ flex: '1', marginRight: '1rem', maxWidth: '210px' }}>
                    <span className="p-float-label" style={{ maxWidth: '210px' }}>
                    <InputNumber
                        id="lastPlaceToScoreRelay"
                        style={{ maxWidth: '210px' }}
                        value={lastPlaceToScoreRelay === undefined ? null : lastPlaceToScoreRelay}
                        onValueChange={(e) => onNumberInputChange('lastPlaceToScoreRelay', e.value!)}
                        onChange={(e) => handleLastPlaceChangeRelay(e.value!)}
                    />
                    <label htmlFor="lastPlaceToScoreRelay">Last place to score</label>
                    </span>
                </div>
                <div style={{ display: 'flex', gap: '5px', flex: '1', flexWrap: 'wrap', maxWidth: '800px' }}>
                    {Array.from({ length: lastPlaceToScoreRelay }, (_, index) => (
                    <div key={index} style={{ width: '10.5%', marginBottom: '0' }}>
                        <span className="p-float-label">
                        <InputText
                            id={`homeTeam${index}`}
                            style={{ width: '100%'}}
                            value={(relayScoring && relayScoring[index]) || ''}
                            onChange={(e) => handlePointsChangeRelay(index, e.target.value)}
                        />
                        <label htmlFor={`homeTeam${index}`}>{index + 1}.</label>
                        </span>
                    </div>
                    ))}
                </div>
            </div>

        </div>
    </Card>
  );};

export default AwardsAndScoring;
