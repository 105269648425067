import React, { useState } from "react";
import { Athlete, Rostergroup, TableAthlete } from "../../index";
import { DataTable } from "primereact/datatable";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { Button } from "primereact/button";
import RosterActionsMenu from "./RosterActionsMenu";
import GroupChip from "./GroupChip";
import { Toolbar } from "primereact/toolbar";
import AddAthleteModal from "../modal/AddAthleteModal";
import AssignAthletesToGroupModal from "../modal/AssignAthletesToGroupModal";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { InputText } from "primereact/inputtext";

interface Props {
  athletes: Athlete[];
  groupName: string;
}

const RosterTable = (props: Props) => {
  const [selectedAthletes, setSelectedAthletes] = useState<Athlete[]>([]);
  const [globalFilter, setGlobalFilter] = useState<string>("");

  const accept = () => {
    console.log("Athletes removed");
  };

  const reject = () => {
    console.log("Deletion cancelled");
  };

  const confirm2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    confirmPopup({
      target: event.currentTarget,
      message: `Are you sure you want to remove athletes from  ${props.groupName}?`,
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-start">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => {
            const target = e.target as HTMLInputElement;
            setGlobalFilter(target.value);
          }}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  return (
    <div>
      <ConfirmPopup />
      <Toolbar
        start={
          <div className="flex gap-2">
            <AssignAthletesToGroupModal groupName={props.groupName} />
            <Button
              severity="danger"
              icon="pi pi-minus"
              tooltip="Remove from group"
              tooltipOptions={{ position: "top" }}
              label="Remove"
              onClick={confirm2}
              disabled={selectedAthletes.length === 0}
            />
          </div>
        }
      ></Toolbar>
      <DataTable
        value={props.athletes}
        selectionMode="checkbox"
        selection={selectedAthletes}
        onSelectionChange={(e) => setSelectedAthletes(e.value)}
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25]}
        globalFilter={globalFilter}
        header={header}
      >
        <Column selectionMode="multiple" />
        <Column sortable field="firstName" header="First name" />
        <Column sortable field="middleInitial" header="M.I." />
        <Column sortable field="lastName" header="Last name" />
        <Column
          sortable
          header="Groups"
          className="w-3"
          body={GroupChip}
        ></Column>
        <Column sortable field="birthday" header="D.O.B" />
        <Column
          sortable
          style={{ width: "5rem" }}
          body={<RosterActionsMenu />}
        ></Column>
      </DataTable>
    </div>
  );
};

export default RosterTable;
