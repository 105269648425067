import React from "react";
import { useState, useEffect } from "react";
import CircleIcon from "../../lib/icons/CircleIcon";
import styled from "styled-components";

interface PwdTextProps {
  isValid: boolean;
}

interface PwdIconProps {
  alt: string;
  isValid: boolean;
}

interface ValidationResults {
  minLength: boolean;
  hasNumber: boolean;
  hasSpecialCharacter: boolean;
  hasUppercase: boolean;
  hasLowercase: boolean;
}

interface PasswordValidationProps {
  password: string;
}

const PasswordValidation: React.FC<PasswordValidationProps> = ({
  password,
}) => {
  const [validationResults, setValidationResults] = useState({
    minLength: false,
    hasNumber: false,
    hasSpecialCharacter: false,
    hasUppercase: false,
    hasLowercase: false,
  });

  // Add an effect to update validationResults whenever the password changes
  useEffect(() => {
    validatePasswordChange(password);
  }, [password]);

  const validatePasswordChange = (newPassword: string) => {
    const validationResult = {
      minLength: newPassword.length >= 8,
      hasNumber: /\d/.test(newPassword),
      hasSpecialCharacter: /[!@#$%^&*]/.test(newPassword),
      hasUppercase: /[A-Z]/.test(newPassword),
      hasLowercase: /[a-z]/.test(newPassword),
    };

    setValidationResults(validationResult);
  };

  return (
    <div>
      <PwdTextStyledBold>Password must be:</PwdTextStyledBold>
      <PwdIconTextPair id="pair1">
        <PwdIconStyled
          alt="icon"
          isValid={validationResults.minLength || password.length === 0}
        >
          <CircleIcon />
        </PwdIconStyled>
        <PwdTextStyled
          isValid={validationResults.minLength || password.length === 0}
        >
          Minimum 8 characters
        </PwdTextStyled>
      </PwdIconTextPair>
      <PwdIconTextPair id="pair2">
        <PwdIconStyled
          alt="icon"
          isValid={validationResults.hasNumber || password.length === 0}
        >
          <CircleIcon />
        </PwdIconStyled>
        <PwdTextStyled
          isValid={validationResults.hasNumber || password.length === 0}
        >
          Contains at least 1 number
        </PwdTextStyled>
      </PwdIconTextPair>
      <PwdIconTextPair id="pair3">
        <PwdIconStyled
          alt="icon"
          isValid={
            validationResults.hasSpecialCharacter || password.length === 0
          }
        >
          <CircleIcon />
        </PwdIconStyled>
        <PwdTextStyled
          isValid={
            validationResults.hasSpecialCharacter || password.length === 0
          }
        >
          Contains at least 1 special character
        </PwdTextStyled>
      </PwdIconTextPair>
      <PwdIconTextPair id="pair4">
        <PwdIconStyled
          alt="icon"
          isValid={validationResults.hasUppercase || password.length === 0}
        >
          <CircleIcon />
        </PwdIconStyled>
        <PwdTextStyled
          isValid={validationResults.hasUppercase || password.length === 0}
        >
          Contains at least 1 uppercase letter
        </PwdTextStyled>
      </PwdIconTextPair>
      <PwdIconTextPair id="pair5">
        <PwdIconStyled
          alt="icon"
          isValid={validationResults.hasLowercase || password.length === 0}
        >
          <CircleIcon />
        </PwdIconStyled>
        <PwdTextStyled
          isValid={validationResults.hasLowercase || password.length === 0}
        >
          Contains at least 1 lowercase letter
        </PwdTextStyled>
      </PwdIconTextPair>
    </div>
  );
};

const PwdIconTextPair = styled.div`
  display: flex;
  align-items: beginning;
  margin-top: 5px;
  margin-bottom: 5px;
`;
const PwdIconStyled = styled.div<PwdIconProps>`
  width: 16px;
  margin-right: 10px;
  color: ${(props) => (props.isValid ? "#15130A" : "#EB5757")};

  svg {
    width: 100%;
  }
`;

const PwdTextStyled = styled.span<PwdTextProps>`
  font-size: 13px;
  color: ${(props) => (props.isValid ? "#15130A" : "#EB5757")};
`;
const PwdTextStyledBold = styled.p`
  font-size: 13px;
  font-weight: bold;
  margin-top: 12px;
`;

export { PasswordValidation };
