import React from "react";

const CircleIcon = () => {
  return (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Circle" clip-path="url(#clip0_281_869)">
<path id="Union" fill-rule="evenodd" clip-rule="evenodd" d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM14.6667 8C14.6667 11.6819 11.6819 14.6667 8 14.6667C4.3181 14.6667 1.33333 11.6819 1.33333 8C1.33333 4.3181 4.3181 1.33333 8 1.33333C11.6819 1.33333 14.6667 4.3181 14.6667 8Z" fill="#6A6E7D"/>
</g>
<defs>
<clipPath id="clip0_281_869">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>
  );
};

export default CircleIcon;
