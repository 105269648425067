import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import StateDropdown from '../../common/StatesDropDown';
import { InputNumber } from 'primereact/inputnumber';

interface MeetInfoProps {
    title: string;
    meetName: string;
    facilityName: string;
    facilityAddress: string;
    facilityCity: string;
    facilityState: string;
    facilityZipCode: number;
    onInputChange: (field: string, value: string) => void;
    onNumberInputChange: (field: string, value: number | null) => void;
    onStateChange: (field: string, value: string) => void;
  }

  const MeetInfo: React.FC<MeetInfoProps> = ({
    title,
    meetName,
    facilityName,
    facilityAddress,
    facilityCity,
    facilityState,
    facilityZipCode,
    onInputChange,
    onNumberInputChange,
    onStateChange,
  })=> {

  return (
<Card title={title} >
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: '1', marginRight: '1rem' }}>
            <span  className="p-float-label">
            <InputText id="meetName" style={{ width: '100%' }} value={meetName} onChange={(e) => onInputChange('meetName', e.target.value)} />
            <label htmlFor="meetName">Meet Name</label>
            </span>
        </div>
        <p>Facility Information:</p>
        <div style={{ display: 'flex' }} className="mb-3">
            <div style={{ flex: '1', marginRight: '1rem' }}>
                <span className="p-float-label">
                    <InputText id="facilityName" style={{ width: '100%' }} value={facilityName} onChange={(e) => onInputChange('facilityName', e.target.value)} />
                    <label htmlFor="facilityName">Name</label>
                </span>
            </div>
            <div style={{ flex: '1', marginRight: '1rem' }}>
            <span className="p-float-label">
                <InputText id="facilityAddress" style={{ width: '100%' }}  value={facilityAddress} onChange={(e) => onInputChange('facilityAddress', e.target.value)} />
                <label htmlFor="facilityAddress">Address</label>
            </span>
            </div>
        </div>
        <div style={{ display: 'flex' }} className="mb-2">
        <div style={{ flex: '1', marginRight: '1rem' }}>
            <span className="p-float-label">
                <InputText id="facilityCity" style={{ width: '100%' }}  value={facilityCity} onChange={(e) => onInputChange('facilityCity', e.target.value)} />
                <label htmlFor="facilityCity">City</label>
            </span>
            </div>
            <div style={{ flex: '1', marginRight: '1rem' }}>
            <span className="p-float-label">
                <InputNumber id="number-input" style={{ width: '100%' }}  value={facilityZipCode === undefined ? null : facilityZipCode} onValueChange={(e) => onNumberInputChange('facilityZipCode', e.value!)} />
                <label htmlFor="number-input">ZIP Code</label>
            </span>
            </div>
        </div>
        <div className="w-6">
            <StateDropdown value={facilityState} onChange={(e) => onStateChange('facilityState', e.value)} placeholder="State" />
        </div>
    </div>
</Card>
  );};

export default MeetInfo;
