import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import { TermsAndConditionsText } from "./TermsAndConditionsText";

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleClick: () => void;
}

export const TermsAndConditionsDialog = (props: Props) => {
  const Footer = (
    <div className="flex justify-content-end col-12">
      <Button
        className="w-8rem"
        label="Got it"
        onClick={() => {
          props.handleClick();
          props.setVisible(false);
        }}
      />
    </div>
  );

  return (
    <Dialog
      header="Terms and Conditions"
      visible={props.visible}
      onHide={() => props.setVisible(false)}
      className="ml-8"
      footer={Footer}
      pt={{
        root: {
          style: { backgroundColor: " #f3f5fa !important" },
        },
      }}
    >
      <p className="p-dialog-content text-sm"> 
        {TermsAndConditionsText}
      </p>
    </Dialog>
  );
};
