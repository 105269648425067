export const TermsAndConditionsText = (
  <pre className="m-0 p-component">
  {`
  1. TERM 
  This Subscription Agreement comes into effect when you click the “I Accept” button or,
  where applicable, when you pay any Subscription Fee (as defined below).
  Depending on the payment model the Organization has opted for (Free, Annual or
  Monthly), the Subscription Agreement will continue for the agreed term of the
  agreement.
  Some Software and Services require a Subscription Fee (as defined below), which must
  be paid otherwise the Organization and its Members will not be able to access the
  Software and/or Services.
  
  2. FEES AND PAYMENTS 
  SUBSCRIPTION FEES 
  Where applicable, before you (and your members) can use or access the Software
  and/or Services, you may be required to pay the applicable subscription fee as set out
  on the online form presented to the Organization (“Subscription Fee”).
  The Subscription Fee, unless stated otherwise, shall be exclusive of any applicable
  taxes.
  The Subscription Fee is non-refundable and will cover the Organization’s (including its
  members) access and use of the Software and Services for the Subscription Term as
  set out above.
  The Subscription Fee is subject to change at the discretion of Swim Genius, LLC. Swim
  Genius will notify the Organization of any such change (increase or decrease), which
  shall not take effect until the end of the current Subscription Term as set out in any such
  notice to the Organization.
  By registering an account with us and using the Software and/or Services, you confirm
  that the information you provide during the registration process, including all
  identification, billing and credit card information, as well as the information already
  stored in the Software and/or Services about you, is accurate. You agree to update
  
  such information promptly if it changes. Without limiting any other provision of this
  Agreement, if you provide any information that is untrue, inaccurate or incomplete, or
  we have reasonable grounds to suspect that such is the case, we reserve the right to
  suspend or terminate your account or subscription and refuse any and all current or
  future use by you of any of the Software and/or Services. You agree not to register or
  create an account on behalf of someone else or create a false or misleading identity on
  any of the Software and/or Services.
  
  AUTO-RENEWALS 
  Where your Subscription is based on auto-renewal either an annual or monthly
  Subscription Fee and if your Organization does not wish to renew, you must inform us
  (by giving us at least thirty (30) days’ notice) before the end date of your current
  Subscription Term, or your subscription will automatically renew for additional periods
  equal to the expiring Subscription Term and we will automatically charge your
  Organization’s account the Subscription Fee. If we take a payment from you
  automatically at the end of your Subscription Term as outlined above and you failed to
  give us notice of your intent not to renew, you will not be entitled to a refund.
  
  FAILURE TO PAY 
  Where you fail to make a payment due or where we are unable to automatically charge
  you (if your Subscription is under auto-renewal), then this may result in the suspension
  or termination of your access to the Software and/or Services, or may limit the features
  and functionality, and/or increase any ongoing fees such as Payment Processing Fees.
  
  We may also charge you a reasonable fee in addition to any owed payments to re-
  instate the Software and/or Services.
  
  ADVERTISEMENTS & MARKETING 
  Swim Genius may display advertisements across its Platforms and Services it delivers
  to the Organization. Swim Genius will retain all revenue obtained from advertisements.
  In placing advertisements, Swim Genius can keep the costs of its Platforms and
  Services to a minimum and allow it to maintain and continue to invest in the
  development of such.
  
  3. PAYMENT PROCESSING 
  The Software and Services we provide may include integrated payment processing
  services that can be utilized by the Organization to accept payments from Members.
  
  In order to receive these Services, you are required to accept our Payment Processing
  Agreement and agree to pay the additional fees (“Payment Processing Fees”) that will
  be contained in an online order presented to the Organization.
  The Payment Processing Fees are charged on a per transaction basis and will differ
  depending on the type of Software and/or Services you are utilizing via Swim Genius.
  The default pricing is contained within the Payment Processing Agreement, and the
  exact pricing for your Organization will be displayed online. If you are unsure or have
  any questions related to pricing, you should contact or our customer support team:
  
  4. USE OF THE SERVICES 
  The Organization agrees to co-operate fully with Swim Genius and must:
  not do anything or omit to do anything that could or would detrimentally affect the
  performance of the Services and/or access to the Software being provided by Swim
  Genius
  In addition to and without prejudice to provisions under the Terms of Use, the
  Organization further acknowledges that they:
  (a) must not use or access the Software and/or Services, or any part thereof, in any
  manner not expressly permitted by the Agreements;
  (b) are solely responsible for the accuracy, quality, integrity and legality of the
  Organization’s Data (as defined below) and for ensuring that the means by which the
  Organization acquired the Organization’s Data are lawful; and
  (c) must not use the Services to store or transmit infringing, libelous, or otherwise
  unlawful or tortious material.
  
  OBLIGATIONS 
  The Organization is further obligated to inform and ensure its members:
  Where the Organization fails to present any of the terms stated above, Swim Genius
  reserves the right to present default information that will inform the Organization’s
  Members to contact the Organization to identify what terms and conditions apply
  (including the Terms of Use). For the sake of completeness, any information presented
  by Swim Genius does not and should not be considered as relieving the Organization’s
  obligations under applicable law or this Subscription Agreement. The Organization
  accepts and agrees that they shall be solely responsible and liable for any of their
  Members and their Members’ use of the Software and/or Service. No Member shall be
  
  deemed to be a third party beneficiary of this Subscription Agreement, and the
  Organization shall not make any warranties, representations or commitments to a
  Member which would (i) imply an endorsement by Swim Genius; (ii) purport to bind
  Swim Genius to any legal obligations owed by the Organization to the Members; or (iii)
  entitle any Member to enforce the terms of this Subscription Agreement against Swim
  Genius.
  Your privilege to use the Software and Services depends on your compliance with the
  conduct guidelines set forth above, the Acceptable Use Policy set forth in our Terms of
  Use, and with the terms of the Agreements. We reserve the right to revoke your
  privileges to use all or a portion of the Software and Services and/or take any other
  appropriate measures to enforce these conduct guidelines and the Agreements.
  
  5. LICENSE 
  GRANT OF LICENSE 
  Subject to compliance with the terms of this Subscription Agreement, Swim Genius
  
  grants the Organization (including its members) a limited, non-exclusive, non-
  transferable, revocable license to use and access the Software and Services that has
  
  been agreed to be provided by Swim Genius during the applicable Subscription Term.
  Swim Genius warrants that it is the owner (except in relation to any third party or open
  source software, as set out in any applicable license terms) of the Software or otherwise
  has the right to grant the Organization the license granted in this Subscription
  Agreement for the purposes of delivering the Services and/or accessing the Software.
  
  LIMITATIONS 
  Organization shall not sell, sublicense, lend, or otherwise transfer or provide access to
  the Software to any Third Party without the express written consent of Swim Genius.
  The Organization shall not reverse engineer, decompile, disassemble, or customize the
  Software, including but not limited to, creating any software interface with the Software,
  without the express written consent of Swim Genius.
  If the Organization breaches, or in the reasonable opinion of Swim Genius has
  breached, the above license terms, then Swim Genius may immediately cease the
  Organization’s (including its Members’) access to any Software and/or Services without
  liability.
  
  OWNERSHIP 
  As between Swim Genius and the Organization, Swim Genius owns (or has the legal
  rights in) all intellectual property rights in the Software and/or Services, Swim Genius
  trademarks, logos, business model, any Additional IPR (as defined below), and any
  other rights not expressly granted to Organization, and nothing in this Subscription
  Agreement shall be deemed to convey to the Organization any right, title, or interest in
  or to the Software and Services or any portion thereof except for the limited rights
  expressly granted herein.
  
  ADDITIONAL IPR 
  In the event that the Organization and/or its members make or observe any new
  discovery, improvement or invention concerning Swim Genius’s intellectual property
  rights, including submitting comments, information, questions, data, ideas,
  enhancement requests, recommendations, descriptions of processes, or other
  information (“Additional IPR”), then the Organization and/or its Members shall not make
  or seek to make commercial gain from such Additional IPR or attempt to secure any
  other proprietary rights to protect any such Additional IPR, except with the prior written
  consent of Swim Genius. The Organization and/or its members agree to do all acts
  necessary to confirm that absolute title in any and all Additional IPR has passed or will
  pass to Swim Genius. You agree that Swim Genius owns all right, title and interest in all
  Additional IPR, and you hereby irrevocably assign and agree to assign all right, title and
  interest in and to such Additional IPR and all associated intellectual property rights to
  Swim Genius.
  
  6. WARRANTIES AND DISCLAIMERS 
  MUTUAL WARRANTIES 
  Both parties represent and warrant that:
  (a) they have the authority to enter into this Subscription Agreement and that their
  signatories are duly authorized and empowered to sign this Subscription Agreement on
  their behalf; and
  (b) they will comply with all applicable laws.
  
  ORGANIZATION WARRANTIES 
  The Organization represents and warrants to Swim Genius that any content and/or
  intellectual property provided to Swim Genius (including without limitation, any
  photographs, drawings, or works of art, trademarks, videos, and logos) do not violate
  
  the rights of any third party. The Organization agrees to indemnify and keep Swim
  Genius indemnified for any alleged or actual breach of this warranty.
  
  SWIM GENIUS WARRANTIES 
  Swim Genius will provide the Software and the Services in a professional and
  workmanlike manner and free from any unreasonable defects. Swim Genius will use
  commercially reasonable means to fix any defect in the Software and/or Services that
  may arise.
  
  DISCLAIMER 
  Aside from these warranties or as set out in this Subscription Agreement, to the extent
  permissible by law, the Software and Services are provided without warranty of any
  kind, express, statutory, implied or otherwise, including but not limited to any implied
  warranty of merchantability, non-infringement, or fitness for a particular purpose or use.
  The Software and Services are provided on an “as-is” and “as-available” basis. Swim
  Genius does not warrant that the Software and Services are or will be free of errors
  and/or viruses, uninterrupted, will meet your requirements, or operate in the
  configuration or with the hardware or software you use.
  With respect to malfunctioning Software, Swim Genius’s entire liability and the
  Organization’s exclusive remedy shall be the repair or replacement of the Software.
  
  7. LIMITATION OF LIABILITY 
  Nothing in this Subscription Agreement shall limit or exclude either of Swim Genius’s or
  the Organization’s liability for: (a) death or personal injury caused by its negligence; (b)
  fraud or fraudulent misrepresentation; or (c) any other liability that cannot be excluded
  by applicable law.
  In no event shall the organization or Swim Genius be liable for any special, incidental,
  indirect, consequential, exemplary or punitive damages (including, without limitation,
  any damages based on loss of profits, loss of use, business interruption or loss or
  corruption of data), even if either you or we have been advised of the possibility of such
  damages. The foregoing limitations shall apply regardless of the cause or the form of
  action (whether breach of contract, breach of warranty, negligence, strict liability or
  otherwise).
  Swim Genius’s total liability to the Organization in respect of all other losses arising out
  of or in connection with the Subscription Agreement, whether in contract, tort (including
  negligence), breach of statutory duty, or otherwise, shall be limited to the total amount
  paid by the Organization under the Subscription Agreement (including Subscription
  Fees and Payment Processing Fees) in the preceding twelve (12) months.
  
  8. DATA PROTECTION 
  SWIM GENIUS AS SERVICE PROVIDER FOR ORGANIZATION DATA 
  Unless otherwise specified or agreed by the parties, the Organization acknowledges
  and agrees that Swim Genius shall act as service provider (a data processor) for any of
  the Organization Data, provided, however, to the extent any Organization Data is also
  Swim Genius Data (as set forth below), , Swim Genius’s use of such Swim Genius Data
  shall be governed by its Privacy Policy. For data for which Swim Genius is a service
  provider, Swim Genius shall act on the Organization’s instructions and shall not copy,
  sell or reuse the Organization Data, save as expressly permitted in this Subscription
  Agreement and, as applicable, the Data Processing Agreement, as updated and notified
  to the Organization from time to time.
  Nothing in this Agreement shall affect Organization’s ownership and rights to use the
  Organization Data and all customer personal information obtained independently of the
  Services by the Organization, whether prior to or during the Term of this Subscription
  Agreement.
  
  Organization acknowledges that where Organization agrees to the integration of a third-
  party technology within its members registration pathway, some existing or newly
  generated Organization Data may be shared with both Swim Genius and the relevant
  third-party technology provider in order to enable the proper functioning of the
  technology and improve the user experience. Where Organization data flows to Swim
  Genius, Swim Genius shall continue to act as a service provider / processor as set out
  in the Clause immediately above. Where Organization Data flows to the third-party
  technology provider (‘Provider”), such sharing is subject to the Provider’s policy;
  depending on the integration and the Organization’s use of the Provider, such Provider
  may act as an independent business/controller or a service/provider processor. As
  between the Parties, Organization, and not Swim Genius, will be deemed to have
  shared the Organization Data with the Provider.
  
  SWIM GENIUS DATA RIGHTS 
  For the avoidance of doubt, Swim Genius is the data owner and controller of all
  personal data obtained directly and independently by Swim Genius from Administrators
  and Members of the Organization who use Swim Genius owned and branded systems,
  including all Registration Data and Usage Data (collectively, “Swim Genius Data”). It is
  acknowledged that Swim Genius data, which includes Administrator and Member sign-on 
  and access credential data, including but not limited to name, email address and
  date of birth, as a data controller.
  Organization agrees that Swim Genius may process Organization Data to generate
  nonpersonal data by means of aggregation, pseudonymisation and/or anonymisation,
  and to use that data (along with any other similar data (e.g. anonymous survey results,
  general usage data or other information generated by Swim Genius under this
  Subscription Agreement) for its own commercial purposes, such as for business
  planning and to improve its products and services, systems and tools.
  
  9. ADMINISTRATION
  
  RELATIONSHIP OF THE PARTIES 
  The parties are independent contractors. This Subscription Agreement does not create
  a partnership, franchise, joint venture, agency, fiduciary or employment relationship
  between the parties.
  
  NO THIRD-PARTY BENEFICIARIES 
  There are no third party beneficiaries to this Agreement.
  
  WAIVER 
  No failure or delay by either party in exercising any right under this Agreement shall
  constitute a waiver of that right. Other than as expressly stated herein, the remedies
  provided herein are in addition to, and not exclusive of, any other remedies of a party at
  law or in equity.
  
  SEVERABILITY 
  If any provision of this Agreement is held by a court of competent jurisdiction to be
  contrary to law, the provision shall be modified by the court and interpreted so as best to
  accomplish the objectives of the original provision to the fullest extent permitted by law,
  and the remaining provisions of this Agreement shall remain in effect.
  
  ASSIGNMENT 
  The Organization may not assign any of its rights or obligations under this Subscription
  Agreement. Swim Genius may assign any of its rights or obligations under this
  Subscription Agreement without the prior consent of the Organization.
  
  ENTIRE AGREEMENT 
  This Subscription Agreement, including all exhibits and addenda hereto and all order
  forms, the Swim Genius Terms of Use and any associated Data Protection Agreement,
  constitute the entire agreement between the parties and supersedes all prior and
  contemporaneous agreements, proposals or representations, written or oral, concerning
  its subject matter. No modification, amendment, or waiver of any provision of this
  Agreement shall be effective unless in writing and either signed or accepted
  electronically by the party against whom the modification, amendment or waiver is to be
  asserted. 
  
  AGREEMENT TO GOVERNING LAW AND JURISDICTION 
  This Agreement shall be governed, interpreted and construed under the laws of the
  United States and the State of New Jersey without regard to any conflict of law
  principles. The parties shall act in good faith and use commercially reasonable efforts to
  promptly resolve any claim, dispute, controversy or disagreement (each a “Dispute”)
  between the parties under or related to this Agreement. Any Dispute arising out of this
  Agreement which cannot be resolved by the parties shall be governed exclusively by
  binding arbitration initiated and conducted in accordance with the Commercial
  Arbitration Rules of the American Arbitration Association, conducted in the State of New
  Jersey. The arbitrator shall have the power to award reasonable attorneys’ fees and
  costs to the prevailing Party in any arbitration, and either Party shall have the right to
  take appropriate action to enforce any arbitration award in any court having jurisdiction
  over the applicable Party.`}
  </pre>
)