import React from "react";
import { toast, ToastOptions } from "react-toastify";

const toastDefaults: Partial<ToastOptions> = {
  position: "top-right",
  autoClose: 5000,
  theme: "colored",
};

export function showErrorToast(message: string, options?: ToastOptions) {
  toast.error(message, {
    ...toastDefaults,
    ...options,
  });
}

export function showSuccessToast(message: string, options?: ToastOptions) {
  toast.success(message, {
    ...toastDefaults,
    ...options,
  });
}

export function showWarningToast(message: string, options?: ToastOptions) {
  toast.warn(message, {
    ...toastDefaults,
    ...options,
  });
}
