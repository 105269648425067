import React, { useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';

interface SanctionProps {
    title: string;
    sanctioningBody: string;
    sanctionNumber: string;
    adminOfficial: string;
    referee: string;
    safetyDirector: string;
    onInputChange: (field: string, value: string) => void;
    onStateChange: (field: string, value: string) => void;
  }

const Sanction: React.FC<SanctionProps> = ({title,
    sanctioningBody,
    sanctionNumber,
    adminOfficial,
    referee,
    safetyDirector,
    onInputChange,
    onStateChange
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const options = [
    { label: 'USA-Swimming', value: 'USA-Swimming' },
    { label: 'NCAA', value: 'NCAA' },
    { label: 'USMS', value: 'USMS' },
    { label: 'YMCA', value: 'YMCA' },
    { label: 'High School', value: 'High School' },
    { label: 'Other', value: 'Other' },
  ];

  return (
    <Card title={title} className="p-card">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginBottom: '1rem' }}>
          <span className="p-float-label">
            <Dropdown
              id="sanctioningBody"
              value={sanctioningBody}
              options={options}
              onChange={(e) => onStateChange('sanctioningBody', e.value)}
              style={{ width: '100%' }}
            />
            <label htmlFor="sanctioningBody">Sanctioning body</label>
          </span>
        </div>

        <div style={{ display: 'flex', marginBottom: '1rem' }}>
          <div style={{ flex: '1', marginRight: '1rem' }}>
            <span className="p-float-label">
              <InputText id="sanctionNumber" style={{ width: '100%' }} value={sanctionNumber} onChange={(e) => onInputChange('sanctionNumber', e.target.value)}/>
              <label htmlFor="sanctionNumber">Sanction number</label>
            </span>
          </div>

          <div style={{ flex: '1' }}>
            <span className="p-float-label">
              <InputText id="adminOfficial" style={{ width: '100%' }} value={adminOfficial} onChange={(e) => onInputChange('adminOfficial', e.target.value)}/>
              <label htmlFor="adminOfficial">Administrative official</label>
            </span>
          </div>
        </div>

        <div style={{ display: 'flex' }}>
          <div style={{ flex: '1', marginRight: '1rem' }}>
            <span className="p-float-label">
              <InputText id="referee" style={{ width: '100%' }} value={referee} onChange={(e) => onInputChange('referee', e.target.value)}/>
              <label htmlFor="referee">Referee</label>
            </span>
          </div>

          <div style={{ flex: '1' }}>
            <span className="p-float-label">
              <InputText id="oRsD" style={{ width: '100%' }} value={safetyDirector} onChange={(e) => onInputChange('safetyDirector', e.target.value)}/>
              <label htmlFor="oRsD">Operational risk/Safety director</label>
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Sanction;
