import React from "react";

const Swimmer = () => {
  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="p-avatar-menu-icon"
    >
      <path
        d="M6.70557 20C1.9323 20 -0.794501 15.0001 0.205499 14C1.2055 13 3.70577 14.7782 6.95059 14.7782C9.54476 14.7782 13.1587 10.4009 17.2056 10.5C20.4431 10.5793 23.2055 16 22.2055 16.5C21.2055 17.0001 19.5734 15.7027 17.7056 16C15.4538 16.3584 11.4788 20 6.70557 20Z"
        fill="#105F93"
      />
      <path
        d="M6.70557 13.5C4.71876 13.7877 2.81856 13 2.70557 12C2.59257 11 8.20577 7 8.20577 7C8.20577 7 8.19542 6.44086 6.95059 6.15738C5.70577 5.87391 3.70577 6.5 3.20577 6.5C2.70577 6.5 1.47903 5.45502 2.5924 4.47751C3.70577 3.5 6.20577 3.00003 7.70577 3C9.20577 2.99997 11.2058 3.20415 12.2058 4C13.9173 5.36214 16.2058 8.00004 14.7058 9C13.2058 9.99996 9.07027 13.1576 6.70557 13.5Z"
        fill="#105F93"
      />
      <circle cx="17.2061" cy="3" r="3" fill="#105F93" />
    </svg>
  );
};

export default Swimmer;
