import React from "react";
import { Menubar } from "primereact/menubar";
import SGAvatar from "./SGAvatar";
import { FaHome } from "react-icons/fa";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const end = <SGAvatar />;
  const home = (
    <Button
      label="Home"
      icon={(options) => <FaHome {...options.iconProps} />}
      text
      onClick={() => navigate("/profile")}
    />
  );
  return (
    <div>
      <Menubar end={end} start={home} />
    </div>
  );
};

export default Navbar;
