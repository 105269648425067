import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputMask, InputMaskChangeEvent } from "primereact/inputmask";
import React, { useEffect, useState } from "react";
import {UserProfile} from "../../lib/model/backend-types";

interface Props {
  label: string;
  id: string;
}

interface ProfileProps {
  initialData: UserProfile;
  setProfileData: React.Dispatch<React.SetStateAction<UserProfile>>;
}

const ProfileGrid = (props: ProfileProps) => {
  const [isPublic, setIsPublic] = useState(true);
  const { initialData, setProfileData } = props;
  const [localData, setLocalData] = useState<UserProfile>(props.initialData);

  useEffect(() => {
    setProfileData(localData);
  }, [localData, setProfileData]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    // Update the 'profileData' state when an input field changes
    setLocalData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePhoneChange = (event: InputMaskChangeEvent) => {
    const { value } = event;

    // Update the 'profileData' state when the phone input changes
    setLocalData((prevData) => ({
      ...prevData,
      phoneNumber: value === null ? "" : value,
    }));
  };

  const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    // Update the 'profileData' state when the textarea value changes
    setLocalData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className={"formgrid grid align-self-center"}>
      <div className="field col-6 flex flex-column gap-2">
        <label htmlFor="first-name" className="font-semibold">
          First name
        </label>
        <InputText
          placeholder="First name"
          name="firstName"
          value={localData.firstName}
          onChange={handleInputChange}
        />
        <label htmlFor="last-name" className="font-semibold">
          Last name
        </label>
        <InputText
          placeholder="Last name"
          name="lastName"
          value={localData.lastName}
          onChange={handleInputChange}
        />
      </div>
      <div className={"field col-6 flex flex-column gap-2"}>
        <label className="font-semibold" htmlFor="username">
          Email
        </label>
        <InputText
          className="w-full"
          placeholder="Email"
          name="primaryEmail"
          value={localData.primaryEmail}
          onChange={handleInputChange}
        />
        <div className={"field w-full flex flex-column gap-2"}>
          <label className="font-semibold" htmlFor="cell-phone">
            Cell Phone
          </label>
          <form
            onSubmit={() => console.log("submit")}
            className="flex flex-column gap-2 w-full"
          >
            <InputMask
              id="cell-phone"
              mask="(999) 999-9999"
              placeholder="(xxx) xxx-xxxx"
              name="phoneNumber"
              className="w-full"
              value={localData.phoneNumber}
              onChange={handlePhoneChange}
            />
            <div className="flex align-items-center">
              <InputSwitch
                checked={isPublic}
                onChange={() => setIsPublic(!isPublic)}
                className="mr-3"
              />
              <div>Public visibility</div>
            </div>
          </form>
        </div>
      </div>
      <div className={"field col-12 flex flex-column gap-2"}>
        <label className="font-semibold" htmlFor="biography">
          Biography
        </label>
        <InputTextarea
          id="biography"
          className="h-8rem"
          placeholder="Biography"
          name="biography"
          value={localData.biography}
          onChange={handleTextareaChange}
        />
      </div>
    </div>
  );
};
export default ProfileGrid;
