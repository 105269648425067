import React, { useState } from 'react';
import { Card } from 'primereact/card';
import DynamicDataTable from '../../common/DynamicDataTable';

interface SwimEvent {
    'Round': string;
    'Distance': string;
    'Stroke': string;
    'Gender': string;
    'Age group': string;
    'Fee': string;
    'Heat order': string;
    'Relay': string;
    'Lanes': string;
}

interface EventsProps {
    title: string;
    events: SwimEvent[];
    onInputChange: (value: SwimEvent[]) => void;
  }

const Events: React.FC<EventsProps> = ({title,
    events,
    onInputChange,
}) => {

  return (
    <Card title={title} className="p-card">
        <DynamicDataTable
                columns={['Round', 'Distance', 'Stroke', 'Gender', 'Age group', 'Fee', 'Heat order', 'Relay', 'Lanes']}
                />
    </Card>
  );
};

export default Events;