import React, { useEffect, useState} from 'react';
import { Button } from 'primereact/button';
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useNavigate } from "react-router-dom";
import MeetInfo from "./components/MeetInfo";
import Sanction from './components/Sanction';
import Dates from './components/Dates';
import AwardsAndScoring from './components/AwardsAndScoring';
import TypeAndCompetitors from './components/TypeAndCompetitors';
import Events from './components/Events';
import Sessions from './components/Sessions';
import Fees from './components/Fees';
import Thermometer from '../common/StepThermometer';

interface SwimSession {
    'Warm up': string;
    'Start': string;
    'Approx. end': string;
    'Interval': string;
    'Back addition': string;
    'Events': string;
}

interface SwimEvent {
    'Round': string;
    'Distance': string;
    'Stroke': string;
    'Gender': string;
    'Age group': string;
    'Fee': string;
    'Heat order': string;
    'Relay': string;
    'Lanes': string;
}

interface MeetInfoData {
    meetName: string;
    facilityName: string;
    facilityAddress: string;
    facilityCity: string;
    facilityState: string;
    facilityZipCode: number;
    sanctioningBody: string;
    sanctionNumber: string;
    adminOfficial: string;
    referee: string;
    safetyDirector: string;
    startDate: Date;
    endDate: Date;
    ageUpDate: Date;
    entryOpenDate: Date;
    style: string;
    meetCourse: string;
    homeTeam: string;
    competitors: string[];
    maxEntriesWithRelays: number;
    maxIndividualEntries: number;
    maxRelayEntries: number;
    numberOfLanesFinals: number;
    numberOfLanesPrelims: number;
    minAgeForOpenEvents: number;
    individualMaxScore: number;
    relayMaxScore: number;
    onlyARelaysScore: boolean;
    lastPlaceToScore: number;
    individualScoring: string[];
    lastPlaceToScoreRelay: number;
    relayScoring: string[];
    individualPrelimFee: number;
    individualFinalFee: number;
    relayPrelimFee: number;
    relayFinalFee: number;
    facilityFee: number;
    athleteFee: number;
    teamFee: number;
    events: SwimEvent[];
    sessions: SwimSession[];
  }

const CreateMeet: React.FC = () => {
    const [meetInfoData, setMeetInfoData] = useState<MeetInfoData>({} as MeetInfoData);
    const [stepCount, setStepCount] = useState<number>(1);

    const handleMeetInfoInputChange = (field: string, value: string) => {
        setMeetInfoData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleMeetInfoNumberInputChange = (field: string, value: number | null) => {
        setMeetInfoData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleMeetInfoDropDownChange = (field: string, value: string) => {
        setMeetInfoData((prevData) => ({
          ...prevData,
          [field]: value,
        }));
    };

    const handleMeetInfoDateChange = (field: string, value: Date) => {
        setMeetInfoData((prevData) => ({
          ...prevData,
          [field]: value,
        }));
    };

    const handleMeetInfoCheckBoxChange = (field: string, value: boolean) => {
        setMeetInfoData((prevData) => ({
          ...prevData,
          [field]: value,
        }));
    };

    const handleIndividualScoringChange = (newScoring: string[]) => {
        setMeetInfoData((prevMeetInfoData) => ({
          ...prevMeetInfoData,
          individualScoring: newScoring,
        }));

        console.log(meetInfoData);
    };

    const handleRelayScoringChange = (newScoring: string[]) => {
        setMeetInfoData((prevMeetInfoData) => ({
          ...prevMeetInfoData,
          relayScoring: newScoring,
        }));
    };

    const handleEventInfoChange = (value: SwimEvent[]) => {
        setMeetInfoData((prevMeetInfoData) => ({
          ...prevMeetInfoData,
          events: value,
        }));
    };

    const handleSessionInfoChange = (value: SwimSession[]) => {
        setMeetInfoData((prevMeetInfoData) => ({
          ...prevMeetInfoData,
          sessions: value,
        }));
    };

    const navigate = useNavigate();

    const stepBack = () => {
        setStepCount(stepCount - 1);    
    };

    const stepForward = () => {
        setStepCount(stepCount + 1);    
    };
  
  return (
    <div>
        <div className="mb-3">
            <div>
                <h1 className={`text-5xl text-primary text-center mb-2 mt-2 ${stepCount === 4 || stepCount === 5 || stepCount === 7 ? 'mr-5' : ''}`} >Create a new meet</h1>
                <Thermometer level={stepCount} />
            </div>
            {stepCount === 1 && <MeetInfo
                title="Step 1. Meet Information"
                {...meetInfoData}
                onInputChange={handleMeetInfoInputChange}
                onNumberInputChange={handleMeetInfoNumberInputChange}
                onStateChange={handleMeetInfoDropDownChange}
                />
            }
            {stepCount === 2 && <Sanction 
                title="Step 2. Sanction"
                {...meetInfoData}
                onInputChange={handleMeetInfoInputChange}
                onStateChange={handleMeetInfoDropDownChange}
                />
            }
            {stepCount === 3 && <Dates 
                title="Step 3. Dates"
                {...meetInfoData}
                onInputChange={handleMeetInfoDateChange}
                />
            }
            {stepCount === 4 && <TypeAndCompetitors 
                title="Step 4. Type & Competitors"
                {...meetInfoData}
                onInputChange={handleMeetInfoInputChange}
                onNumberInputChange={handleMeetInfoNumberInputChange}
                onStateChange={handleMeetInfoDropDownChange}
                />
            }
            {stepCount === 5 && <AwardsAndScoring 
                title="Step 5. Awards & Scoring"
                {...meetInfoData}
                onInputChange={handleMeetInfoInputChange}
                onNumberInputChange={handleMeetInfoNumberInputChange}
                onStateChange={handleMeetInfoDropDownChange}
                onCheckBoxChange={handleMeetInfoCheckBoxChange}
                onScoringChange={handleIndividualScoringChange}
                onScoringChangeRelay={handleRelayScoringChange}
                />
            }
            {stepCount === 6 && <Fees 
                title="Step 6. Fees"
                {...meetInfoData}
                onNumberInputChange={handleMeetInfoNumberInputChange}
                />
            }
            {stepCount === 7 && <Events
                title="Step 7. Events"     
                {...meetInfoData}
                onInputChange={handleEventInfoChange}
                />
            }
            {stepCount === 8 && <Sessions
                title="Step 8. Sessions"     
                {...meetInfoData}
                onInputChange={handleSessionInfoChange}
                />
            }
        </div>
        <div className="flex space-x-8">
            <Button
                label="Back"
                className="w-6 mr-6"
                onClick={stepBack}
                disabled={stepCount === 1}
            />
            <Button
                label="Next"
                className="header-button-yellow w-6 ml-6"
                onClick={stepForward}
                disabled={stepCount === 8}
            />
        </div>
    </div>
  );
};

export default CreateMeet;
