import { InputSwitch } from "primereact/inputswitch";
import React, { useState } from "react";
import TableContainer from "./components/table/TableContainer";
import { Dictionary, groupBy as _groupBy } from "lodash";
import { Button } from "primereact/button";
import { SGSidebar } from "../common/SGSidebar";

export interface Athlete {
  firstName: string;
  lastName: string;
  middleInitial: string;
  group: string;
  birthday: string;
}

export interface Rostergroup {
  name: string;
  athletes: Athlete[];
}

const fakeAthlete = [
  {
    firstName: "John",
    lastName: "Jacom",
    middleInitial: "L",
    group: "Freestyle",
    birthday: "8/6/1995",
  },
  {
    firstName: "Peter",
    lastName: "Piper",
    middleInitial: "T",
    group: "Freestyle, Butterfly",
    birthday: "8/6/1995",
  },
  {
    firstName: "James",
    lastName: "Bond",
    middleInitial: "T",
    group: "Freestyle, Backstroke",
    birthday: "8/6/1995",
  },
  {
    firstName: "Sean",
    lastName: "Connerry",
    middleInitial: "z",
    group: "Freestyle, Butterfly, Backstroke, Tomato",
    birthday: "8/6/1995",
  },
  {
    firstName: "Carmelo",
    lastName: "Anthony",
    middleInitial: "I",
    group: "Juniors",
    birthday: "8/6/1995",
  },
  {
    firstName: "Zen",
    lastName: "The Destroyer",
    middleInitial: "T",
    group: "Freshman",
    birthday: "8/6/1995",
  },
  {
    firstName: "M",
    lastName: "Doom",
    middleInitial: "F",
    group: "Freshman",
    birthday: "8/6/1995",
  },
] as Athlete[];

const fakeGroups = [
  {
    name: "Freestyle",
    athletes: fakeAthlete,
  },
  {
    name: "Butterfly",
    athletes: fakeAthlete.slice(0, 4),
  },
  {
    name: "Backstroke",
    athletes: fakeAthlete.slice(2, 6),
  },
] as Rostergroup[];

export interface TableAthlete extends Athlete {
  rosterGroup: Rostergroup;
}

const tableAthletes = fakeGroups.flatMap((group) => {
  return group.athletes.map((athlete) => {
    return {
      ...athlete,
      rosterGroup: group,
    };
  });
});

const RosterPage = () => {
  const [splitOnGroup, setSplitOnGroup] = useState(false);
  const athleteMap: Dictionary<Athlete[]> = _groupBy(fakeAthlete, "group");

  return (
    <div className="h-full w-10 flex flex-column gap-1 align-self-center mb-4">
      <h1 className="text-5xl text-primary">Roster</h1>
      <div className="flex justify-content-between">
        <span className="flex align-items-center gap-3">
          <InputSwitch
            checked={splitOnGroup}
            color="secondary"
            onChange={() => setSplitOnGroup(!splitOnGroup)}
          />
          <p>Group view</p>
        </span>
      </div>
      <TableContainer
        splitOnGroup={splitOnGroup}
        athletes={athleteMap}
        tableAthletes={tableAthletes}
      />
    </div>
  );
};

export default RosterPage;
