import React, { useState } from 'react';
import { Card } from 'primereact/card';
import DynamicDataTable from '../../common/DynamicDataTable';

interface SwimSession {
    'Warm up': string;
    'Start': string;
    'Approx. end': string;
    'Interval': string;
    'Back addition': string;
    'Events': string;
}

interface SessionsProps {
    title: string;
    sessions: SwimSession[];
    onInputChange: (value: SwimSession[]) => void;
  }

const Sessions: React.FC<SessionsProps> = ({title,
    sessions,
    onInputChange,
}) => {

  return (
    <Card title={title} className="p-card">
        <DynamicDataTable
            columns={['Warm up', 'Start', 'Approx. end', 'Interval', 'Back addition', 'Events']}
            />
    </Card>
  );
};

export default Sessions;