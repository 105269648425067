import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import React, { useState } from "react";
import AddGroupsModal from "./AddGroupModal";
import { Athlete } from "../../index";

interface Props {
  selectedAthletes: Athlete[];
}

const AssignAthletesModal = (props: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState<string[] | null>();
  return (
    <div>
      <Button
        severity="secondary"
        icon="pi pi-users"
        tooltip="Assign athletes to groups"
        tooltipOptions={{ position: "top" }}
        onClick={() => setIsVisible(!isVisible)}
        label="Assign"
        disabled={props.selectedAthletes.length === 0}
      />
      <Dialog
        header="Assign athletes to groups"
        visible={isVisible}
        style={{ width: "50vw" }}
        onHide={() => setIsVisible(false)}
        footer={
          <div className="flex gap-2 justify-content-end pt-0">
            <Button label="Save" />
          </div>
        }
      >
        <div className="flex flex-column gap-1">
          <MultiSelect
            className="w-full"
            display="chip"
            value={selectedGroups}
            onChange={(e: MultiSelectChangeEvent) => setSelectedGroups(e.value)}
            options={["group1", "group2", "group3", "group4"]}
            pt={{ token: { style: { backgroundColor: "#FECE02" } } }}
          ></MultiSelect>
          <div className="flex justify-content-end">
            <AddGroupsModal />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AssignAthletesModal;
