import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import React, {useState} from "react";
import {InputText} from "primereact/inputtext";
import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import {Team} from "../../lib/model/backend-types";


const CreateTeamModal = () => {

    const lscs = [
        {
            "name": "LSC_UNKNOWN",
            "code": "**"
        },
        {
            "name": "LSC_ADIRONDACK",
            "code": "AD"
        },
        {
            "name": "LSC_ALASKA",
            "code": "AK"
        },
        {
            "name": "LSC_ALLEGHENY_MOUNTAIN",
            "code": "AM"
        },
        {
            "name": "LSC_ARKANSAS",
            "code": "AR"
        },
        {
            "name": "LSC_ARIZONA",
            "code": "AZ"
        },
        {
            "name": "LSC_BORDER",
            "code": "BD"
        },
        {
            "name": "LSC_SOUTHERN_CALIFORNIA",
            "code": "CA"
        },
        {
            "name": "LSC_CENTRAL_CALIFORNIA",
            "code": "CC"
        },
        {
            "name": "LSC_COLORADO",
            "code": "CO"
        },
        {
            "name": "LSC_CONNECTICUT",
            "code": "CT"
        },
        {
            "name": "LSC_FLORIDA_GOLD_COAST",
            "code": "FG"
        },
        {
            "name": "LSC_FLORIDA",
            "code": "FL"
        },
        {
            "name": "LSC_GEORGIA",
            "code": "GA"
        },
        {
            "name": "LSC_GULF",
            "code": "GU"
        },
        {
            "name": "LSC_HAWAII",
            "code": "HI"
        },
        {
            "name": "LSC_IOWA",
            "code": "IA"
        },
        {
            "name": "LSC_INLAND_EMPIRE",
            "code": "IE"
        },
        {
            "name": "LSC_ILLINOIS",
            "code": "IL"
        },
        {
            "name": "LSC_INDIANA",
            "code": "IN"
        },
        {
            "name": "LSC_KENTUCKY",
            "code": "KY"
        },
        {
            "name": "LSC_LOUISIANA",
            "code": "LA"
        },
        {
            "name": "LSC_LAKE_ERIE",
            "code": "LE"
        },
        {
            "name": "LSC_MIDDLE_ATLANTIC",
            "code": "MA"
        },
        {
            "name": "LSC_MARYLAND",
            "code": "MD"
        },
        {
            "name": "LSC_MAINE",
            "code": "ME"
        },
        {
            "name": "LSC_MICHIGAN",
            "code": "MI"
        },
        {
            "name": "LSC_MINNESOTA",
            "code": "MN"
        },
        {
            "name": "LSC_METROPOLITAN",
            "code": "MR"
        },
        {
            "name": "LSC_MISSISSIPPI",
            "code": "MS"
        },
        {
            "name": "LSC_MONTANA",
            "code": "MT"
        },
        {
            "name": "LSC_MISSOURI_VALLEY",
            "code": "MV"
        },
        {
            "name": "LSC_MIDWESTERN",
            "code": "MW"
        },
        {
            "name": "LSC_NORTH_CAROLINA",
            "code": "NC"
        },
        {
            "name": "LSC_NORTH_DAKOTA",
            "code": "ND"
        },
        {
            "name": "LSC_NEW_ENGLAND",
            "code": "NE"
        },
        {
            "name": "LSC_NIAGARA",
            "code": "NI"
        },
        {
            "name": "LSC_NEW_JERSEY",
            "code": "NJ"
        },
        {
            "name": "LSC_NEW_MEXICO",
            "code": "NM"
        },
        {
            "name": "LSC_NORTH_TEXAS",
            "code": "NT"
        },
        {
            "name": "LSC_OHIO",
            "code": "OH"
        },
        {
            "name": "LSC_OKLAHOMA",
            "code": "OK"
        },
        {
            "name": "LSC_OREGON",
            "code": "OR"
        },
        {
            "name": "LSC_OZARK",
            "code": "OZ"
        },
        {
            "name": "LSC_PACIFIC",
            "code": "PC"
        },
        {
            "name": "LSC_PACIFIC_NORTHWEST",
            "code": "PN"
        },
        {
            "name": "LSC_POTOMAC_VALLEY",
            "code": "PV"
        },
        {
            "name": "LSC_SOUTH_CAROLINA",
            "code": "SC"
        },
        {
            "name": "LSC_SOUTH_DAKOTA",
            "code": "SD"
        },
        {
            "name": "LSC_SOUTHEASTERN",
            "code": "SE"
        },
        {
            "name": "LSC_SAN_DIEGO_IMPERIAL",
            "code": "SI"
        },
        {
            "name": "LSC_SIERRA_NEVADA",
            "code": "SN"
        },
        {
            "name": "LSC_SNAKE_RIVER",
            "code": "SR"
        },
        {
            "name": "LSC_SOUTH_TEXAS",
            "code": "ST"
        },
        {
            "name": "LSC_USA_SWIMMING",
            "code": "US"
        },
        {
            "name": "LSC_UTAH",
            "code": "UT"
        },
        {
            "name": "LSC_VIRGINIA",
            "code": "VA"
        },
        {
            "name": "LSC_WISCONSIN",
            "code": "WI"
        },
        {
            "name": "LSC_WEST_TEXAS",
            "code": "WT"
        },
        {
            "name": "LSC_WEST_VIRGINIA",
            "code": "WV"
        },
        {
            "name": "LSC_WYOMING",
            "code": "WY"
        }
    ]


    const [isVisible, setIsVisible] = useState(false);
    const [selectedLsc, setSelectedLsc] = useState(null);
    const [teamData, setTeamData] = useState<Team>({} as Team);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        // Update the 'profileData' state when an input field changes
        setTeamData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleLscDropChange = (event: DropdownChangeEvent) => {
        const { value } = event.target;
        setSelectedLsc(event.target.value)

        // Update the 'profileData' state when an input field changes
        setTeamData((prevData) => ({
            ...prevData,
            LSC: value,
        }));
    };

    return (
        <div>
            <Button
                icon="pi pi-plus"
                label="New"
                tooltip="Create a team"
                onClick={() => setIsVisible(!isVisible)}
                tooltipOptions={{position: "top"}}
            />
            <Dialog
                header="Create a Team"
                visible={isVisible}
                style={{width: "50vw"}}
                onHide={() => setIsVisible(false)}
                footer={
                    <div className="flex gap-2 justify-content-end pt-0">
                        <Button label="Save"/>
                    </div>
                }
            >
                <div className="formgrid grid w-12 gap-3 justify-content-between">
                    <div className="flex flex-column gap-2 w-5">
                        <label htmlFor="teamName">Team name</label>
                        <InputText id="teamName" onChange={handleInputChange}/>
                    </div>
                    <div className="flex flex-column gap-2 w-1">
                        <label htmlFor="abbreviation">Abbreviation</label>
                        <InputText id="abbreviation" onChange={handleInputChange}/>
                    </div>
                    <div className="flex flex-column gap-2 w-5">
                        <label htmlFor="lsc">LSC</label>
                        <Dropdown id="lsc" value={selectedLsc}
                                  onChange={handleLscDropChange}
                                  options={lscs}
                                  optionLabel="code"
                                  placeholder="Select an LSC"
                        />
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default CreateTeamModal;
