import { Button } from "primereact/button";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { MenuItem } from "primereact/menuitem";
import { TieredMenu } from "primereact/tieredmenu";
import React, { useRef, useState } from "react";

const RosterActionsMenu = () => {
  const menu = useRef<TieredMenu | null>(null);

  const items: MenuItem[] = [
    {
      label: "Edit Athlete",
      icon: "pi pi-user-edit",
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
    },
  ];

  return (
    <>
      <TieredMenu model={items} popup ref={menu} breakpoint="767px" />
      <Button
        icon="pi pi-ellipsis-v"
        onClick={(e) => menu?.current?.toggle(e)}
        rounded
        text
        aria-label="Filter"
      />
    </>
  );
};

export default RosterActionsMenu;
