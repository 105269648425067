import React from "react";

const Settings = () => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="p-avatar-menu-icon"
    >
      <path
        d="M9.37253 0C10.1065 0.0084595 10.8376 0.0932622 11.554 0.253038C11.8668 0.322785 12.1007 0.583511 12.1362 0.901963L12.3064 2.42881C12.3834 3.12986 12.9753 3.66084 13.681 3.66158C13.8706 3.66188 14.0583 3.62238 14.2335 3.54483L15.6342 2.92956C15.9255 2.80159 16.2658 2.87136 16.4833 3.10362C17.4955 4.18464 18.2493 5.48115 18.688 6.89558C18.7826 7.20058 18.6738 7.53203 18.4167 7.7215L17.1752 8.63659C16.8211 8.89679 16.6119 9.31001 16.6119 9.74946C16.6119 10.1889 16.8211 10.6021 17.176 10.8629L18.4186 11.7783C18.6757 11.9677 18.7847 12.2992 18.6901 12.6043C18.2515 14.0185 17.4981 15.3149 16.4865 16.3961C16.2692 16.6283 15.9291 16.6983 15.6378 16.5706L14.2315 15.9545C13.8292 15.7784 13.3671 15.8042 12.9869 16.024C12.6067 16.2437 12.3536 16.6312 12.3053 17.0677L12.1362 18.5944C12.1014 18.9092 11.8725 19.1682 11.5644 19.2415C10.1159 19.5861 8.60683 19.5861 7.15838 19.2415C6.85026 19.1682 6.6214 18.9092 6.58653 18.5944L6.41771 17.07C6.36812 16.6343 6.11469 16.248 5.73478 16.029C5.35486 15.81 4.89356 15.7843 4.49255 15.9594L3.08593 16.5756C2.79457 16.7033 2.45439 16.6333 2.23713 16.4009C1.22498 15.3185 0.471545 14.0205 0.0335523 12.6048C-0.0607863 12.2999 0.0482132 11.9686 0.305198 11.7793L1.54853 10.8633C1.90267 10.6031 2.11183 10.1899 2.11183 9.75046C2.11183 9.31101 1.90267 8.89779 1.54807 8.63725L0.305513 7.72285C0.0481474 7.53345 -0.0609522 7.20178 0.033719 6.89658C0.47247 5.48215 1.2263 4.18564 2.23848 3.10462C2.45594 2.87236 2.79629 2.80259 3.0876 2.93056L4.48797 3.54572C4.89091 3.72256 5.35415 3.69585 5.73612 3.47269C6.11644 3.25209 6.36964 2.86422 6.41852 2.42764L6.5886 0.901963C6.62411 0.58335 6.85821 0.322541 7.17115 0.252942C7.88843 0.0934198 8.6203 0.00865372 9.37253 0ZM9.36005 6.74995C7.70319 6.74995 6.36005 8.0931 6.36005 9.74995C6.36005 11.4068 7.70319 12.75 9.36005 12.75C11.0169 12.75 12.36 11.4068 12.36 9.74995C12.36 8.0931 11.0169 6.74995 9.36005 6.74995Z"
        fill="#105F93"
      />
    </svg>
  );
};

export default Settings;
