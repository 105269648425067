import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import { InputText } from "primereact/inputtext";
import VerifyOtpForgotPasswordDialog from "../components/VerifyOtpForgotPasswordDialog";
import { postUsersForgotPassword } from "../../lib/service/user-resource";
import Axios, { AxiosError } from "axios";
import { showErrorToast } from "../../common/Toast";
import { PasswordValidation } from "../../unauthenticated/sign-up/PasswordValidation";
import { ProgressSpinner } from "primereact/progressspinner";
import { useNavigate } from "react-router-dom";

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleClick: () => void;
}

export const ForgotPasswordDialog = (props: Props) => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showOtp, setOtpVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSendOtp = async () => {
    try {
      setLoading(true);
      const forgotPwReq = {
        username,
      };

      const response = await postUsersForgotPassword(forgotPwReq);

      if (response.status >= 400) {
        // Handle 4xx and 5xx errors
        showErrorToast(response.errorMessage);
      } else {
        // It's a successful response (ApiResponse<T>)
        setOtpVisible(!showOtp);
      }
    } catch (error) {
      // Handle other types of errors
      console.error(error);
    }

    setLoading(false);
  };

  const Footer = (
    <div className="flex justify-content-end col-12">
      <Button
        className="w-8rem"
        label="Send link"
        onClick={() => {
          handleSendOtp();
          props.setVisible(false);
        }}
      />
    </div>
  );

  return (
    <div>
      {loading && (
        <div className="loading-overlay">
          <ProgressSpinner />
        </div>
      )}
      <div>
        <Dialog
          header="Reset password"
          visible={props.visible}
          onHide={() => props.setVisible(false)}
          className="ml-8"
          footer={Footer}
          pt={{
            root: {
              style: { backgroundColor: "#fafafa !important" },
            },
          }}
        >
          <p>
            The verification link will be sent to your email address on file
          </p>
          <InputText
            className="p-inputtext w-12 mb-2"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <InputText
            className="p-inputtext w-12"
            placeholder="New password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <PasswordValidation password={password} />
        </Dialog>
        <VerifyOtpForgotPasswordDialog
          visible={showOtp}
          username={username}
          password={password}
          setVisible={setOtpVisible}
          handleClick={() => console.log("hello")}
        />
      </div>
    </div>
  );
};
