import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';

interface TypeAndCompetitorsProps {
    title: string;
    style: string;
    meetCourse: string;
    homeTeam: string;
    competitors: string[];
    maxEntriesWithRelays: number;
    maxIndividualEntries: number;
    maxRelayEntries: number;
    numberOfLanesFinals: number;
    numberOfLanesPrelims: number;
    minAgeForOpenEvents: number;
    onInputChange: (field: string, value: string) => void;
    onStateChange: (field: string, value: string) => void;
    onNumberInputChange: (field: string, value: number | null) => void;
  }

const TypeAndCompetitors: React.FC<TypeAndCompetitorsProps> = ({title,
    style,
    meetCourse,
    homeTeam,
    competitors,
    maxEntriesWithRelays,
    maxIndividualEntries,
    maxRelayEntries,
    numberOfLanesFinals,
    numberOfLanesPrelims,
    minAgeForOpenEvents,
    onInputChange,
    onStateChange,
    onNumberInputChange
}) => {

    const meetStyleOptions = [
        { label: 'Invitational', value: 'Invitational' },
        { label: 'Championship', value: 'Championship' },
        { label: 'Dual', value: 'Dual' },
        { label: 'Trimeet', value: 'Trimeet' }
    ];

    const meetCourseOptions = [
        { label: 'Yards', value: 'Yards' },
        { label: 'LCM', value: 'LCM' },
        { label: 'SCM', value: 'SCM' }
    ];

  return (
    <Card title={title} className="p-card">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', marginBottom: '1rem' }}>
          <div style={{ flex: '1', marginRight: '1rem' }}>
            <span className="p-float-label">
                <Dropdown
                id="style"
                value={style}
                options={meetStyleOptions}
                onChange={(e) => onStateChange('style', e.value)}
                style={{ width: '100%' }}
                />              
            <label htmlFor="style">Meet style</label>
            </span>
          </div>

          <div style={{ flex: '1' }}>
            <span className="p-float-label">
                <Dropdown
                    id="meetCourse"
                    value={meetCourse}
                    options={meetCourseOptions}
                    onChange={(e) => onStateChange('meetCourse', e.value)}
                    style={{ width: '100%' }}
                />  
                <label htmlFor="meetCourse">Meet course</label>
            </span>
          </div>
        </div>

        <div style={{ display: 'flex', marginBottom: '1rem' }}>
          <div style={{ flex: '1', marginRight: '1rem' }}>
            <span className="p-float-label">
            <InputText id="homeTeam" style={{ width: '100%' }}  value={homeTeam} onChange={(e) => onInputChange('homeTeam', e.target.value)} />
              <label htmlFor="homeTeam">Home team</label>
            </span>
          </div>

          <div style={{ flex: '1' }}>
            <span className="p-float-label">
                <Dropdown
                    id="competitors"
                    value={competitors}
                    options={meetCourseOptions}
                    onChange={(e) => onStateChange('competitors', e.value)}
                    style={{ width: '100%' }}
                /> 
                <label htmlFor="competitors">Competitors</label>
            </span>
          </div>
        </div>
        <p style={{margin: 0}}>Entry information:</p>
        <div style={{ display: 'flex', marginBottom: '1rem' }}>
          <div style={{ flex: '1', marginRight: '1rem' }}>
            <span className="p-float-label">
              <InputNumber id="maxEntriesWithRelays" style={{ width: '100%' }} value={maxEntriesWithRelays === undefined ? null : maxEntriesWithRelays} onValueChange={(e) => onNumberInputChange('maxEntriesWithRelays', e.value!)}/>
              <label htmlFor="maxEntriesWithRelays">Max entries with relays</label>
            </span>
          </div>

          <div style={{ flex: '1', marginRight: '1rem' }}>
            <span className="p-float-label">
              <InputNumber id="maxIndividualEntries" style={{ width: '100%' }} value={maxIndividualEntries === undefined ? null : maxIndividualEntries} onValueChange={(e) => onNumberInputChange('maxIndividualEntries', e.value!)}/>
              <label htmlFor="maxIndividualEntries">Max individual entries</label>
            </span>
          </div>

          <div style={{ flex: '1' }}>
            <span className="p-float-label">
              <InputNumber id="maxRelayEntries" style={{ width: '100%' }} value={maxRelayEntries === undefined ? null : maxRelayEntries} onValueChange={(e) => onNumberInputChange('maxRelayEntries', e.value!)}/>
              <label htmlFor="maxRelayEntries">Max relay entries</label>
            </span>
          </div>
        </div>
        <p style={{margin: 0}}>Lane information:</p>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: '1', marginRight: '1rem' }}>
            <span className="p-float-label">
              <InputNumber id="numberOfLanesFinals" style={{ width: '100%' }} value={numberOfLanesFinals === undefined ? null : numberOfLanesFinals} onValueChange={(e) => onNumberInputChange('numberOfLanesFinals', e.value!)}/>
              <label htmlFor="numberOfLanesFinals">Lanes in finals</label>
            </span>
          </div>

          <div style={{ flex: '1', marginRight: '1rem' }}>
            <span className="p-float-label">
              <InputNumber id="numberOfLanesPrelims" style={{ width: '100%' }} value={numberOfLanesPrelims === undefined ? null : numberOfLanesPrelims} onValueChange={(e) => onNumberInputChange('numberOfLanesPrelims', e.value!)}/>
              <label htmlFor="numberOfLanesPrelims">Lanes in prelims</label>
            </span>
          </div>

          <div style={{ flex: '1' }}>
            <span className="p-float-label">
              <InputNumber id="minAgeForOpenEvents" style={{ width: '100%' }} value={minAgeForOpenEvents === undefined ? null : minAgeForOpenEvents} onValueChange={(e) => onNumberInputChange('minAgeForOpenEvents', e.value!)}/>
              <label htmlFor="minAgeForOpenEvents">Open event min age</label>
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default TypeAndCompetitors;