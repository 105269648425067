import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

interface SwimEvent {
  'Round': string;
  'Distance': string;
  'Stroke': string;
  'Gender': string;
  'Age group': string;
  'Fee': string;
  'Heat order': string;
  'Relay': string;
  'Lanes': string;
}

interface SwimSession {
  'Warm up': string;
  'Start': string;
  'Approx. end': string;
  'Interval': string;
  'Back addition': string;
  'Events': string;
}

interface DynamicDataTableProps {
  columns: string[];
}

const DynamicDataTable: React.FC<DynamicDataTableProps> = ({ columns }) => {
  const initialData = Array.from({ length: 2 }, () => Object.fromEntries(columns.map((column) => [column, ''])));
  const [data, setData] = useState<any[]>(initialData);

  const onRowDelete = (rowData: any) => {
    const updatedData = data.filter((row) => row !== rowData);
    setData(updatedData);
  };

  const onInputChange = (index: number, column: string, value: string) => {
    if (index < data.length && data[index]) {
      const updatedData = [...data];
      updatedData[index][column] = value;
      setData(updatedData);
    }
  };
  
  const onRowAdd = () => {
    const updatedData = [...data];
    const newRow = Object.fromEntries(columns.map((column) => [column, '']));
    updatedData.push(newRow);
    setData(updatedData);
  };

  function isSwimEvent(obj: any): obj is SwimEvent {
    return 'Round' in obj && 'Distance' in obj && 'Stroke' in obj && 'Gender' in obj && 'Age group' in obj && 'Fee' in obj && 'Heat order' in obj && 'Relay' in obj && 'Lanes' in obj;
  }

  function isSwimSession(obj: any): obj is SwimSession {
    return 'Warm up' in obj && 'Start' in obj && 'Approx. end' in obj && 'Interval' in obj && 'Back addition' in obj && 'Events' in obj;
  }
  
  return (
    // <div>
    //   <DataTable value={data} reorderableRows onRowReorder={(e) => setData(e.value)} scrollable scrollHeight="400px" showGridlines stripedRows size='small'>
    //     <Column rowReorder />
    //     {columns.map((column, index) => (
    //       <Column style={{ width: '80px' }} key={index} field={column} header={column} editor={(props) => (
    //         <InputText
    //           value={props.rowData ? props.rowData[column] : ''}
    //           onChange={(e) => onInputChange(props.rowIndex, column, e.target.value)}
    //           style={{ width: '100%' }}
    //           className="no-border-input"
    //         />
    //       )} />
    //     ))}
    //     <Column header="" body={(rowData: any) => (
    //       <div>
    //         <Button icon="pi pi-trash" onClick={() => onRowDelete(rowData)} />
    //       </div>
    //     )} />
    //   </DataTable>
    //   <Button label="Add Row" onClick={onRowAdd} />
    // </div>
    <div>
        <DataTable value={data} reorderableRows onRowReorder={(e) => setData(e.value)} scrollable scrollHeight="280px" showGridlines stripedRows size='small'>
            <Column rowReorder
                style={{ width: '0px' }}/>
            <Column
                style={{ width: '50px' }}
                header={isSwimEvent(data[0]) ? 'Event #' : isSwimSession(data[0]) ? 'Session #' : ''}
                body={(rowData: any, { rowIndex }: { rowIndex: number }) => rowIndex + 1}
            />
            {columns.map((column, index) => (
            <Column style={{ width: '80px' }} key={index} field={column} header={column} editor={(props) => (
                <InputText
                value={props.rowData ? props.rowData[column] : ''}
                onChange={(e) => onInputChange(props.rowIndex, column, e.target.value)}
                style={{ width: '100%' }}
                className="no-border-input"
                />
            )} />
            ))}
            <Column header="" body={(rowData: any) => (
            <div>
                <Button icon="pi pi-trash" onClick={() => onRowDelete(rowData)} />
            </div>
            )} />
        </DataTable>
        <Button className="mt-2" label="Add Row" onClick={onRowAdd} />
    </div>
  );  
};

export default DynamicDataTable;
