import React from "react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { useState } from "react";
import { postUsersSignIn } from "../../lib/service/user-resource";
import "react-toastify/dist/ReactToastify.css";
import { AxiosError } from "axios";
import { showErrorToast } from "../../common/Toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordDialog } from "../../profile/components/ForgotPasswordDialog";
import { useAuth } from "../../AuthContext";

export const SigninDialog = () => {
  const { login } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const goToSignUp = async () => {
    navigate("/sign-up");
  };

  // Event handler for the "Log in" button
  const handleLogin = async () => {
    try {
      setLoading(true);
      const signInReq = {
        username,
        password,
      };

      const response = await postUsersSignIn(signInReq);

      if (response.status >= 400) {
        // Handle 4xx and 5xx errors
        showErrorToast(response.errorMessage);
      } else {
        // It's a successful response (ApiResponse<T>)
        localStorage.setItem("token", response.data.accessToken!);
        localStorage.setItem("loginUsername", username);

        const user = { username: username };
        login(user);

        navigate("/profile");
      }
    } catch (error) {
      // Handle other types of errors
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <div>
      {loading && (
        <div className="loading-overlay">
          <ProgressSpinner />
        </div>
      )}
      <div className="flex flex-column align-items-center gap-6 ">
        <img src="/SG-logo.png" alt="image" width={179} height={150}></img>
        <div className="flex flex-column align-items-start w-6">
          <div className="align-self-start font-bold text-6xl">
            <p className="font-bold">Welcome back!</p>
          </div>
          <div className="flex gap-4 flex-column align-items-center w-12">
            <InputText
              className="p-inputtext-lg w-12"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Password
              className="p-inputtext-lg"
              style={{ width: "100%" }}
              feedback={false}
              toggleMask
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              className="forgot-password align-self-end"
              style={{
                paddingRight: "0px !important",
                paddingTop: "0px !important",
              }}
              label="Forgot Password?"
              link
              size="large"
              onClick={() => setVisible(!visible)}
            />

            <Button
              className=" w-12"
              label="Log in"
              size="large"
              onClick={handleLogin}
            />
            <Button label="Create account" link onClick={goToSignUp}></Button>
          </div>
        </div>
        <ForgotPasswordDialog
          visible={visible}
          setVisible={setVisible}
          handleClick={() => console.log("hello")}
        />
      </div>
    </div>
  );
};

export default SigninDialog;
