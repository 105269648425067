import React from "react";
import { Athlete } from "../../index";
import { Chip } from "primereact/chip";

const GroupChip = (athlete: Athlete) => {
  const groups = athlete.group.split(",");
  return (
    <div className="flex gap-2 w-3">
      {groups.map((g, index) => (
        <Chip
          label={g}
          key={index}
          pt={{
            root: {
              style: {
                background: "rgba(254, 206, 2, .75)",
                borderRadius: "20px",
                fontWeight: 600,
                fontSize: "14px",
              },
            },
          }}
        ></Chip>
      ))}
    </div>
  );
};

export default GroupChip;
