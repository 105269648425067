import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import React, { useState } from "react";

interface Props {
  groupName: string;
}

const AssignAthletesToGroupModal = (props: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState<string[] | null>();
  return (
    <div>
      <Button
        icon="pi pi-plus"
        tooltip="Add athletes to group"
        tooltipOptions={{ position: "top" }}
        onClick={() => setIsVisible(!isVisible)}
        label="Assign"
      />
      <Dialog
        header={`Assign athletes to ${props.groupName}`}
        visible={isVisible}
        style={{ width: "50vw" }}
        onHide={() => setIsVisible(false)}
        footer={
          <div className="flex gap-2 justify-content-end pt-0">
            <Button label="Save" />
          </div>
        }
      >
        <div className="flex gap-1">
          <MultiSelect
            className="w-full"
            display="chip"
            value={selectedGroups}
            onChange={(e: MultiSelectChangeEvent) => setSelectedGroups(e.value)}
            options={[
              "Jim L. Coates",
              "Mike F. Krohn",
              "Jason D. Siegel",
              "Tom Z. Ford",
              "John J. Jacob",
              "Vladimir C. Putin",
            ]}
            pt={{ token: { style: { backgroundColor: "#FECE02" } } }}
          ></MultiSelect>
        </div>
      </Dialog>
    </div>
  );
};

export default AssignAthletesToGroupModal;
