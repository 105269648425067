import { FileUpload } from "primereact/fileupload";
import React from "react";

const ProfilePhoto = () => {
  return (
    <div className="container">
      <img
        src="/jason.png"
        width={0}
        height={0}
        sizes="100vw"
        className="border-round-top-xl"
        style={{ width: "100%", height: "auto" }}
        alt="image"
        onClick={(e) => {
          e.stopPropagation(), e.preventDefault();
        }}
      ></img>
      <div className="overlay border-round-top-xl pb-1">
        <FileUpload
          mode="basic"
          name="demo[]"
          url="/api/upload"
          accept="image/*"
          className="overlay-content"
          maxFileSize={1000000}
          onUpload={() => console.log("upload")}
        />
      </div>
    </div>
  );
};

export default ProfilePhoto;
