import { Button } from "primereact/button";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { toast } from "react-toastify";

interface Props {
  iconButton?: boolean;
}

const groups = ["group1", "group2", "group3", "group4", "group5", "group6"];

const AddGroupsModal = (props: Props) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Dialog
        header="Groups settings"
        visible={visible}
        onHide={() => setVisible(!visible)}
      >
        <div className="flex w-30rem flex-column gap-3">
          {groups.map((g, i) => (
            <div className={"p-inputgroup"} key={i}>
              <InputText className={"w-full"} defaultValue={g} />
              <Button outlined icon="pi pi-save" className="p-button-success" />
              <Button outlined icon={"pi pi-trash"} severity="danger" />
            </div>
          ))}
          <Button label="Add group" />
        </div>
      </Dialog>
      {props.iconButton ? (
        <Button
          onClick={() => setVisible(!visible)}
          icon="pi pi-cog"
          tooltip="Group settings"
          tooltipOptions={{ position: "top" }}
        />
      ) : (
        <Button
          label={"Manage groups"}
          text
          onClick={() => setVisible(!visible)}
        />
      )}
    </>
  );
};

export default AddGroupsModal;
