import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import React, { useState } from "react";
import AddGroupsModal from "./AddGroupModal";

const AddAthleteModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div>
      <Button
        icon="pi pi-plus"
        label="Add"
        tooltip="Add athletes"
        onClick={() => setIsVisible(!isVisible)}
        tooltipOptions={{ position: "top" }}
      />
      <Dialog
        header="Add Athlete"
        visible={isVisible}
        style={{ width: "50vw" }}
        onHide={() => setIsVisible(false)}
        footer={
          <div className="flex gap-2 justify-content-end pt-0">
            <Button label="Save" />
            <Button label="Save and Add Next" text />
          </div>
        }
      >
        <div className="formgrid grid w-12 gap-3 justify-content-between">
          <div className="flex flex-column gap-2 w-5">
            <label htmlFor="firstName">First name</label>
            <InputText id="firstName" />
          </div>
          <div className="flex flex-column gap-2 w-1">
            <label htmlFor="firstName">M.I.</label>
            <InputText id="firstName" />
          </div>
          <div className="flex flex-column gap-2 w-5">
            <label htmlFor="lastName">Last name</label>
            <InputText id="lastName" />
          </div>
          <div className="flex flex-column gap-2 w-5">
            <label htmlFor="lastName">Birth date</label>
            <Calendar />
          </div>
          <div className="flex flex-column gap-2 w-1">
            <label htmlFor="lastName">Age</label>
            <InputText disabled />
          </div>
          <div className="flex flex-column gap-2 w-5">
            <label htmlFor="lastName">Gender</label>
            <Dropdown options={["Male", "Female"]} />
          </div>
          <div className="flex flex-column gap-2 w-12">
            <label htmlFor="groups">Groups</label>
            <MultiSelect />
            <div className="flex justify-content-end pt-0">
              <AddGroupsModal />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AddAthleteModal;
