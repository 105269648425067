import React, { useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Athlete, Rostergroup, TableAthlete } from "../../index";
import { Dictionary } from "lodash";
import GroupRosterTable from "./GroupRosterTable";
import flatMap from "lodash/flatMap";
import RosterTable from "./RosterTable";
import _groupBy from "lodash/groupBy";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

interface Props {
  splitOnGroup: boolean;
  athletes: Dictionary<Athlete[]>;
  tableAthletes: TableAthlete[];
}

const TableContainer = (props: Props) => {
  const [groupAthletes, setGroupAthletes] = useState(
    _groupBy(props.tableAthletes, "rosterGroup.name")
  );
  const [newGroup, setNewGroup] = useState<string>("");
  const [showAddGrup, setShowAddGroup] = useState(false);

  const handleNewGroup = (value: string) => {
    let tempGroups = {};
    setGroupAthletes(tempGroups);
  };

  return (
    <div className="h-full">
      {props.splitOnGroup ? (
        <>
          <Accordion multiple activeIndex={[]}>
            {Object.keys(groupAthletes).map((key) => (
              <AccordionTab
                key={key}
                header={key}
                pt={{ headerTitle: { className: "text-2xl font-semibold" } }}
              >
                <GroupRosterTable
                  groupName={key}
                  athletes={groupAthletes[key]}
                />
              </AccordionTab>
            ))}
          </Accordion>

          {showAddGrup ? (
            <div className="p-inputgroup w-full mt-2 pb-5">
              <InputText
                className="w-full"
                onChange={(e) => setNewGroup(e.target.value)}
              />
              <Button
                outlined
                icon="pi pi-check"
                className="p-button-success"
                onClick={() => {
                  setGroupAthletes({ ...groupAthletes, [newGroup]: [] });
                  setShowAddGroup(false);
                }}
              />
              <Button
                outlined
                icon={"pi pi-trash"}
                severity="danger"
                onClick={() => setShowAddGroup(false)}
              />
            </div>
          ) : (
            <div className="flex justify-content-end">
              <Button
                rounded
                icon="pi pi-plus"
                className="mt-2 align-self-end mb-5"
                tooltip="Add group"
                tooltipOptions={{ position: "bottom" }}
                onClick={() => setShowAddGroup(true)}
              ></Button>
            </div>
          )}
        </>
      ) : (
        <RosterTable athletes={flatMap(props.athletes)}></RosterTable>
      )}
    </div>
  );
};

export default TableContainer;
