import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import InputGrid from "./InputGrid";
import { Button } from "primereact/button";
import { useQueryClient } from "@tanstack/react-query";
import { showErrorToast, showSuccessToast } from "../../common/Toast";
import {usePostProfiles, usePutProfiles} from "../../lib/service/user-profile-resource";
import {UserProfile} from "../../lib/model/backend-types";

interface Props {
  visible: boolean;
  initialData: UserProfile;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleClick: () => void;
}

export const EditProfile = (props: Props) => {
  const [p, setProfileData] = useState<UserProfile>({} as UserProfile);
  const postProfilesMutation = usePostProfiles({
    mutation: {
      onSuccess: () => showSuccessToast("Profile Updated"),
      onError: () => showErrorToast("Error updated profile"),
    },
  });
  const putProfilesMutation = usePutProfiles({
    mutation: {
      onSuccess: () => showSuccessToast("Profile Updated"),
      onError: () => showErrorToast("Error updated profile"),
    },
  });
  const queryClient = useQueryClient();

  const Footer = (
    <div className="flex justify-content-end col-12">
      <Button
        className="w-8rem"
        label="Save"
        disabled={
          p === undefined ||
          p?.firstName?.length === 0 ||
          p?.username?.length === 0 ||
          p?.lastName?.length === 0
        }
        onClick={async () => {
          if (
            props.initialData === undefined ||
            props.initialData === ({} as UserProfile)
          ) {
            p.username = localStorage.getItem("loginUsername") || "";

            // Perform the mutation by calling the mutation function
            const result = await postProfilesMutation.mutateAsync({ data: p });

            console.log(postProfilesMutation);

            postProfilesMutation.isSuccess &&
              showSuccessToast("Profile updated");

            // Handle the result (success)
            console.log("Mutation Result:", result);

            // Now, manually trigger the refetch after a successful mutation
            queryClient.invalidateQueries({
              queryKey: ["username", localStorage.getItem("loginUsername")],
            });
          } else if (props.initialData !== undefined) {
            p.username = localStorage.getItem("loginUsername") || "";

            // Perform the mutation by calling the mutation function
            const result = await putProfilesMutation.mutateAsync({ data: p });

            // Handle the result (success)
            console.log("Mutation Result:", result);

            // Now, manually trigger the refetch after a successful mutation
            queryClient.invalidateQueries({
              queryKey: ["username", localStorage.getItem("loginUsername")],
            });
          }
          props.setVisible(false);
        }}
      />
    </div>
  );

  return (
    <Dialog
      header="Edit Profile"
      visible={props.visible}
      onHide={() => props.setVisible(false)}
      className="ml-8"
      footer={Footer}
      pt={{
        root: {
          style: { backgroundColor: " #f3f5fa !important" },
        },
      }}
    >
      <InputGrid
        initialData={props.initialData}
        setProfileData={setProfileData}
      />
    </Dialog>
  );
};
