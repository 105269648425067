import React from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useNavigate } from "react-router-dom";
import SGAvatar from "../../common/SGAvatar";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toolbar } from "primereact/toolbar";
import CreateTeamModal from "./CreateTeamModal";
import _defaultTo from "lodash/defaultTo";
import {useGetProfilesUsername} from "../../lib/service/user-profile-resource";

const MyTeamsTable = () => {
  const { data, isLoading, error, isSuccess } = useGetProfilesUsername(
    _defaultTo(localStorage.getItem("loginUsername"), ""),
    {
      query: {
        queryKey: ["username"],
      },
    }
  );
  const navigate = useNavigate();

  const logo = <SGAvatar />;
  const loading = () => (
    <div className="flex align-items-center">
      <ProgressSpinner />
    </div>
  );

  return (
    <div className="h-full">
      <Toolbar
        start={
          <div className="flex gap-2">
            <CreateTeamModal />
          </div>
        }
      ></Toolbar>
      {isLoading ? (
        <DataTable value={[]} emptyMessage={loading}>
          <Column header="Logo" body={logo}></Column>
          <Column field="name" header="Name" sortable></Column>
          <Column header="Type" body="Type coming in update" sortable></Column>
          <Column field="teamCode" header="Team Code" sortable></Column>
        </DataTable>
      ) : (
        <DataTable
          value={data?.teams}
          onRowClick={() => navigate("/roster")}
          selectionMode="single"
          emptyMessage="No teams found, please add a team"
        >
          <Column header="Logo" body={logo}></Column>
          <Column field="name" header="Name" sortable></Column>
          <Column header="Type" body="Type coming in update" sortable></Column>
          <Column field="teamCode" header="Team Code" sortable></Column>
        </DataTable>
      )}
    </div>
  );
};

export default MyTeamsTable;
