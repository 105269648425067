/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * SG User Service
 * Swim Genius user service, a custom implementation of AWS cognito
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation
} from '@tanstack/react-query'
import type {
  MutationFunction,
  UseMutationOptions
} from '@tanstack/react-query'
import type {
  ChangePwReq,
  ConfirmForgotPwReq,
  ConfirmReq,
  ErrorMessage,
  ForgotPwReq,
  ForgotPwResponse,
  SignInReq,
  SignInResponse,
  SignUpReq
} from '../model/user-types'
import { UseSGMutatorIdentity } from '../../SGMutatorIdentity';
import type { ErrorType, BodyType } from '../../SGMutatorIdentity';



// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


/**
 * Initiates a change password request
 */
export const postUsersChangePassword = (
    changePwReq: BodyType<ChangePwReq>,
 options?: SecondParameter<typeof UseSGMutatorIdentity>,) => {
      
      
      return UseSGMutatorIdentity<unknown>(
      {url: `/users/changePassword`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: changePwReq
    },
      options);
    }
  


export const getPostUsersChangePasswordMutationOptions = <TError = ErrorType<ErrorMessage>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUsersChangePassword>>, TError,{data: BodyType<ChangePwReq>}, TContext>, request?: SecondParameter<typeof UseSGMutatorIdentity>}
): UseMutationOptions<Awaited<ReturnType<typeof postUsersChangePassword>>, TError,{data: BodyType<ChangePwReq>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postUsersChangePassword>>, {data: BodyType<ChangePwReq>}> = (props) => {
          const {data} = props ?? {};

          return  postUsersChangePassword(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostUsersChangePasswordMutationResult = NonNullable<Awaited<ReturnType<typeof postUsersChangePassword>>>
    export type PostUsersChangePasswordMutationBody = BodyType<ChangePwReq>
    export type PostUsersChangePasswordMutationError = ErrorType<ErrorMessage>

    export const usePostUsersChangePassword = <TError = ErrorType<ErrorMessage>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUsersChangePassword>>, TError,{data: BodyType<ChangePwReq>}, TContext>, request?: SecondParameter<typeof UseSGMutatorIdentity>}
) => {

      const mutationOptions = getPostUsersChangePasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Confirms a aws cognito user
 */
export const postUsersConfirm = (
    confirmReq: BodyType<ConfirmReq>,
 options?: SecondParameter<typeof UseSGMutatorIdentity>,) => {
      
      
      return UseSGMutatorIdentity<unknown>(
      {url: `/users/confirm`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: confirmReq
    },
      options);
    }
  


export const getPostUsersConfirmMutationOptions = <TError = ErrorType<ErrorMessage>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUsersConfirm>>, TError,{data: BodyType<ConfirmReq>}, TContext>, request?: SecondParameter<typeof UseSGMutatorIdentity>}
): UseMutationOptions<Awaited<ReturnType<typeof postUsersConfirm>>, TError,{data: BodyType<ConfirmReq>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postUsersConfirm>>, {data: BodyType<ConfirmReq>}> = (props) => {
          const {data} = props ?? {};

          return  postUsersConfirm(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostUsersConfirmMutationResult = NonNullable<Awaited<ReturnType<typeof postUsersConfirm>>>
    export type PostUsersConfirmMutationBody = BodyType<ConfirmReq>
    export type PostUsersConfirmMutationError = ErrorType<ErrorMessage>

    export const usePostUsersConfirm = <TError = ErrorType<ErrorMessage>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUsersConfirm>>, TError,{data: BodyType<ConfirmReq>}, TContext>, request?: SecondParameter<typeof UseSGMutatorIdentity>}
) => {

      const mutationOptions = getPostUsersConfirmMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Confirms forgot password request
 */
export const postUsersConfirmForgotPassword = (
    confirmForgotPwReq: BodyType<ConfirmForgotPwReq>,
 options?: SecondParameter<typeof UseSGMutatorIdentity>,) => {
      
      
      return UseSGMutatorIdentity<unknown>(
      {url: `/users/confirmForgotPassword`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: confirmForgotPwReq
    },
      options);
    }
  


export const getPostUsersConfirmForgotPasswordMutationOptions = <TError = ErrorType<ErrorMessage>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUsersConfirmForgotPassword>>, TError,{data: BodyType<ConfirmForgotPwReq>}, TContext>, request?: SecondParameter<typeof UseSGMutatorIdentity>}
): UseMutationOptions<Awaited<ReturnType<typeof postUsersConfirmForgotPassword>>, TError,{data: BodyType<ConfirmForgotPwReq>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postUsersConfirmForgotPassword>>, {data: BodyType<ConfirmForgotPwReq>}> = (props) => {
          const {data} = props ?? {};

          return  postUsersConfirmForgotPassword(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostUsersConfirmForgotPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof postUsersConfirmForgotPassword>>>
    export type PostUsersConfirmForgotPasswordMutationBody = BodyType<ConfirmForgotPwReq>
    export type PostUsersConfirmForgotPasswordMutationError = ErrorType<ErrorMessage>

    export const usePostUsersConfirmForgotPassword = <TError = ErrorType<ErrorMessage>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUsersConfirmForgotPassword>>, TError,{data: BodyType<ConfirmForgotPwReq>}, TContext>, request?: SecondParameter<typeof UseSGMutatorIdentity>}
) => {

      const mutationOptions = getPostUsersConfirmForgotPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Initiates forgot password request
 */
export const postUsersForgotPassword = (
    forgotPwReq: BodyType<ForgotPwReq>,
 options?: SecondParameter<typeof UseSGMutatorIdentity>,) => {
      
      
      return UseSGMutatorIdentity<ForgotPwResponse>(
      {url: `/users/forgotPassword`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: forgotPwReq
    },
      options);
    }
  


export const getPostUsersForgotPasswordMutationOptions = <TError = ErrorType<ErrorMessage>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUsersForgotPassword>>, TError,{data: BodyType<ForgotPwReq>}, TContext>, request?: SecondParameter<typeof UseSGMutatorIdentity>}
): UseMutationOptions<Awaited<ReturnType<typeof postUsersForgotPassword>>, TError,{data: BodyType<ForgotPwReq>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postUsersForgotPassword>>, {data: BodyType<ForgotPwReq>}> = (props) => {
          const {data} = props ?? {};

          return  postUsersForgotPassword(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostUsersForgotPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof postUsersForgotPassword>>>
    export type PostUsersForgotPasswordMutationBody = BodyType<ForgotPwReq>
    export type PostUsersForgotPasswordMutationError = ErrorType<ErrorMessage>

    export const usePostUsersForgotPassword = <TError = ErrorType<ErrorMessage>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUsersForgotPassword>>, TError,{data: BodyType<ForgotPwReq>}, TContext>, request?: SecondParameter<typeof UseSGMutatorIdentity>}
) => {

      const mutationOptions = getPostUsersForgotPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Signs in a user
 */
export const postUsersSignIn = (
    signInReq: BodyType<SignInReq>,
 options?: SecondParameter<typeof UseSGMutatorIdentity>,) => {
      
      
      return UseSGMutatorIdentity<SignInResponse>(
      {url: `/users/signIn`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: signInReq
    },
      options);
    }
  


export const getPostUsersSignInMutationOptions = <TError = ErrorType<ErrorMessage>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUsersSignIn>>, TError,{data: BodyType<SignInReq>}, TContext>, request?: SecondParameter<typeof UseSGMutatorIdentity>}
): UseMutationOptions<Awaited<ReturnType<typeof postUsersSignIn>>, TError,{data: BodyType<SignInReq>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postUsersSignIn>>, {data: BodyType<SignInReq>}> = (props) => {
          const {data} = props ?? {};

          return  postUsersSignIn(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostUsersSignInMutationResult = NonNullable<Awaited<ReturnType<typeof postUsersSignIn>>>
    export type PostUsersSignInMutationBody = BodyType<SignInReq>
    export type PostUsersSignInMutationError = ErrorType<ErrorMessage>

    export const usePostUsersSignIn = <TError = ErrorType<ErrorMessage>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUsersSignIn>>, TError,{data: BodyType<SignInReq>}, TContext>, request?: SecondParameter<typeof UseSGMutatorIdentity>}
) => {

      const mutationOptions = getPostUsersSignInMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Signs up a user through aws cognito
 */
export const postUsersSignup = (
    signUpReq: BodyType<SignUpReq>,
 options?: SecondParameter<typeof UseSGMutatorIdentity>,) => {
      
      
      return UseSGMutatorIdentity<unknown>(
      {url: `/users/signup`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: signUpReq
    },
      options);
    }
  


export const getPostUsersSignupMutationOptions = <TError = ErrorType<ErrorMessage>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUsersSignup>>, TError,{data: BodyType<SignUpReq>}, TContext>, request?: SecondParameter<typeof UseSGMutatorIdentity>}
): UseMutationOptions<Awaited<ReturnType<typeof postUsersSignup>>, TError,{data: BodyType<SignUpReq>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postUsersSignup>>, {data: BodyType<SignUpReq>}> = (props) => {
          const {data} = props ?? {};

          return  postUsersSignup(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostUsersSignupMutationResult = NonNullable<Awaited<ReturnType<typeof postUsersSignup>>>
    export type PostUsersSignupMutationBody = BodyType<SignUpReq>
    export type PostUsersSignupMutationError = ErrorType<ErrorMessage>

    export const usePostUsersSignup = <TError = ErrorType<ErrorMessage>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUsersSignup>>, TError,{data: BodyType<SignUpReq>}, TContext>, request?: SecondParameter<typeof UseSGMutatorIdentity>}
) => {

      const mutationOptions = getPostUsersSignupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    