import React from "react";
import ReactDOM from "react-dom/client";
import "./theme.css";
import "./overrides.css";
import "primeicons/primeicons.css";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  RouterProvider,
  RouteObject,
} from "react-router-dom";
import RosterPage from "./roster";
import "/node_modules/primeflex/primeflex.css";
import ProfilePage from "./profile";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import SigninPage from "./unauthenticated/sign-in/sign-in";
import SignupPage from "./unauthenticated/sign-up/sign-up";
import Home from "./unauthenticated/home/index";
import { PrimeReactProvider } from "primereact/api";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { SGSidebar } from "./common/SGSidebar";
import { AuthProvider } from "./AuthContext";
import { PrivateRoute } from "./PrivateRoute";
import { ToastContainer } from "react-toastify";
import TeamsPage from "./teams";
import CreateMeet from "./create-meet";

const routerConfig: RouteObject[] = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/sign-in",
    element: <SigninPage />,
  },
  {
    path: "/sign-up",
    element: <SignupPage />,
  },
  {
    path: "/roster",
    element: (
      <SGSidebar>
        <RosterPage />
        <PrivateRoute />
      </SGSidebar>
    ),
  },
  {
    path: "/teams",
    element: (
      <SGSidebar>
        <TeamsPage />
        <PrivateRoute />
      </SGSidebar>
    ),
  },
  {
    path: "/profile",
    element: (
      <SGSidebar>
        <ProfilePage />
        <PrivateRoute />
      </SGSidebar>
    ),
  },
  {
    path: "/create-meet",
    element: (
      <SGSidebar>
        <CreateMeet />
      </SGSidebar>
    ),
  },
];

const router = createBrowserRouter(routerConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <AuthProvider>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <PrimeReactProvider>
          <ToastContainer closeButton={false} />
          <RouterProvider router={router} />
          <ReactQueryDevtools initialIsOpen={false} />
        </PrimeReactProvider>
      </QueryClientProvider>
    </React.StrictMode>
  </AuthProvider>
);

reportWebVitals();
