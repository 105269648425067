import React from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
  MenuItemStyles,
} from "react-pro-sidebar";
import { SidebarHeader } from "./SidebarHeader";

import { Typography } from "./Typography";
import Navbar from "./navbar";
import { useNavigate } from "react-router-dom";

interface Props {
  children: any;
}

const themes = {
  light: {
    sidebar: {
      backgroundColor: "#105F93",
      color: "#FFFFFF",
    },
    menu: {
      menuContent: "#0C507C",
      icon: "#59d0ff",
      hover: {
        backgroundColor: "#2777AB",
        color: "#b6c8d9",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
  },
};

// hex to rgba converter
const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const SGSidebar = ({ children }: Props) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const navigate = useNavigate();

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: "17px",
      fontWeight: 600,
    },
    icon: {
      color: themes.light.menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes.light.menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(themes.light.menu.menuContent, 1)
          : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes.light.menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(themes.light.menu.hover.backgroundColor, 1),
        color: themes.light.menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
      }}
    >
      <Sidebar
        collapsed={collapsed}
        backgroundColor={hexToRgba(themes.light.sidebar.backgroundColor, 1)}
        rootStyles={{
          color: themes.light.sidebar.color,
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <SidebarHeader
            rtl={false}
            style={{ marginBottom: "24px", marginTop: "16px" }}
            onClick={() => setCollapsed(!collapsed)}
          />
          <div style={{ flex: 1, marginBottom: "32px" }}>
            <div style={{ padding: "0 24px", marginBottom: "8px" }}>
              <Typography
                fontSize="20px"
                fontWeight={600}
                style={{ opacity: collapsed ? 0 : 0.9, letterSpacing: "0.5px" }}
              >
                Menu
              </Typography>
            </div>
            <Menu menuItemStyles={menuItemStyles}>
              <MenuItem
                  icon={
                    <img src="roster.svg" alt={"None"} height={22} width={22} />
                  }
                  onClick={() => navigate("/teams")}
              >
                My Teams
              </MenuItem>
              <SubMenu
                label="Schedule"
                icon={
                  <img src="calendar.svg" alt={"None"} height={20} width={20} />
                }
              >
                <MenuItem> Current schedule</MenuItem>
                <MenuItem> Meets</MenuItem>
                <MenuItem> Events</MenuItem>
              </SubMenu>
              <MenuItem
                icon={
                  <img src="MeetOps.svg" alt={"None"} height={18} width={18} />
                }
              >
                Meet Ops
              </MenuItem>
              <SubMenu
                label="Reports"
                icon={
                  <img src="reports.svg" alt={"None"} height={22} width={24} />
                }
              >
                <MenuItem> Create new</MenuItem>
                <MenuItem> View my reports</MenuItem>
              </SubMenu>
              <SubMenu
                label="Connections"
                icon={
                  <img
                    src="Connections.svg"
                    alt={"None"}
                    height={20}
                    width={20}
                  />
                }
              >
                <MenuItem> Product</MenuItem>
                <MenuItem> Orders</MenuItem>
                <MenuItem> Credit card</MenuItem>
              </SubMenu>
            </Menu>
          </div>
        </div>
      </Sidebar>

      <main
        style={{
          width: "100%",
          backgroundColor: "#f3f5fa",
        }}
      >
        <div className={"flex flex-column "} style={{ color: "#44596e" }}>
          <Navbar />
          <div
            style={{ height: "89vh" }}
            className="flex w-full overflow-x-visible justify-content-center overflow-y-scroll"
          >
            {children}
          </div>
        </div>
      </main>
    </div>
  );
};
