import React from 'react';
import { Card } from 'primereact/card';

interface FeaturePanelProps {
    title: string;
    descriptions: string[];  
    imageSrc: string;
    imageSrc2?: string;
    isLast: boolean;
    isImageLeft: boolean;
  }

const FeaturePanel: React.FC<FeaturePanelProps> = ({ title, descriptions, imageSrc, imageSrc2, isLast, isImageLeft }) => {
    return (
    <div className="feature-panel">
        <Card className="feature-panel-inner">
        <div className="panel-content">
            {isImageLeft && (
            <div className="feature-image-container">
                {imageSrc && <img src={imageSrc} alt={title} className={`feature-image ${!imageSrc2 ? 'feature-image3' : ''}`} />}
                {imageSrc2 && <img src={imageSrc2} alt={title} className="feature-image feature-image2" />}
            </div>
            )}
            <div className="feature-content">
            <h1 className="app-description-title">{title}</h1>
            <ul className="app-list-style-none">
                {descriptions.map((desc, index) => (
                <li key={index} className="app-description-subtitle">
                    <i className="pi pi-circle-fill m-1" style={{ color: "rgba(254, 206, 2, .75)" }} />
                    {desc}
                </li>
                ))}
            </ul>
            </div>
            {!isImageLeft && (
            <div className="feature-image-container">
                {imageSrc && <img src={imageSrc} alt={title} className={`feature-image ${!imageSrc2 ? 'feature-image3' : ''}`} />}
                {imageSrc2 && <img src={imageSrc2} alt={title} className="feature-image feature-image2" />}
            </div>
            )}
        </div>
        </Card>
        <div className={!isLast ? 'panel-gutter' : ''} />
    </div>
    );
};

export default FeaturePanel;