import React from "react";
import MyTeamsTable from "./components/MyTeamsTable";

const TeamsPage = () => {

    return (
        <div className="h-full w-10 flex flex-column gap-1 align-self-center mb-4">
            <h1 className="text-5xl text-primary">My Teams</h1>
            <div className="flex justify-content-between">
            </div>
            <MyTeamsTable/>
        </div>
    );
};

export default TeamsPage;
