import React from "react";

const Profile = () => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="p-avatar-menu-icon"
    >
      <path
        d="M13.7506 11.9953C14.9927 11.9953 15.9995 13.0022 15.9995 14.2442V15.1626C15.9995 15.736 15.8203 16.295 15.4869 16.7615C13.941 18.9248 11.4167 19.9964 7.99646 19.9964C4.57549 19.9964 2.05253 18.9243 0.510368 16.7599C0.178405 16.294 0 15.7362 0 15.1641V14.2442C0 13.0022 1.00686 11.9953 2.24888 11.9953H13.7506ZM7.99646 0C10.7579 0 12.9965 2.23858 12.9965 5C12.9965 7.76142 10.7579 10 7.99646 10C5.23503 10 2.99646 7.76142 2.99646 5C2.99646 2.23858 5.23503 0 7.99646 0Z"
        fill="#105F93"
      />
    </svg>
  );
};

export default Profile;
