import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { showErrorToast } from "../../common/Toast";
import { postUsersConfirmForgotPassword } from "../../lib/service/user-resource";
import { AxiosError } from "axios";
import { ProgressSpinner } from "primereact/progressspinner";
import { useNavigate } from "react-router-dom";

interface Props {
  visible: boolean;
  username: string;
  password: string;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleClick: () => void;
}

export const VerifyOtpForgotPasswordDialog = (props: Props) => {
  const [otp, setOtp] = useState<string[]>(["", "", "", "", "", ""]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const username = props.username;

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;
    if (!/^\d*$/.test(value)) {
      return;
    }

    const newOtp = [...otp];
    newOtp[index] = value;

    if (index < 5 && value) {
      const nextInput = document.getElementById(`otp-input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }

    setOtp(newOtp);
  };

  const handleVerifyOtp = async () => {
    try {
      setLoading(true);
      const otpAsString = otp.join("");
      const verifyOtp = {
        confirmationCode: otpAsString,
        newPassword: props.password,
        username,
      };

      const response = await postUsersConfirmForgotPassword(verifyOtp);

      if (response.status >= 400) {
        // Handle 4xx and 5xx errors
        showErrorToast(response.errorMessage);
      } else {
        // It's a successful response (ApiResponse<T>)
        props.setVisible(false);
        navigate("/sign-in");
      }
    } catch (error) {
      // Handle other types of errors
      console.error(error);
    }

    setLoading(false);
  };

  const dialogFooter = (
    <div className="flex justify-content-end col-12">
      <Button
        className="w-8rem"
        label="Submit"
        onClick={() => {
          handleVerifyOtp();
          props.setVisible(false);
        }}
      />
    </div>
  );

  return (
    <div>
      {loading && (
        <div className="loading-overlay">
          <ProgressSpinner />
        </div>
      )}
      <div>
        <Dialog
          header="Verify OTP"
          visible={props.visible}
          className="ml-8"
          style={{ width: "500px" }}
          footer={dialogFooter}
          onHide={() => props.setVisible(false)}
        >
          <p className="p-dialog-content text-xl">
            Enter the verification code that was sent to your email
          </p>
          <div className="flex flex-row gap-1 align-items-center w-12">
            {otp.map((digit, index) => (
              <InputText
                className="p-inputtext w-2 m-2"
                style={{ textAlign: "center" }}
                key={index}
                id={`otp-input-${index}`}
                value={digit}
                onChange={(e) => handleChange(e, index)}
                maxLength={1}
              />
            ))}
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default VerifyOtpForgotPasswordDialog;
