import React, { useEffect } from "react";
import { Route, RouteProps, Routes } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";

type PrivateRouteProps = RouteProps;

export function PrivateRoute({ ...rest }: PrivateRouteProps) {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = localStorage.getItem('loginUsername');
    if (!user && !storedUser ) {
      navigate('/sign-in');
    }
  }, [user, navigate]);

  if (!user) {
    return null;
  }

  return (
    <Routes><Route {...rest} /></Routes>
  );
}