import React from 'react';

const Thermometer: React.FC<{ level: number }> = ({ level }) => {
  const renderThermometer = () => {
    const items = [];
    for (let i = 1; i <= 8; i++) {
      const isFilled = i <= level;
      items.push(
        <div key={i} className={`thermometer-item ${isFilled ? 'filled' : ''}`} data-number={i}>
        {i}
        </div>
      );
    }
    return items;
  };

  return <div className={`thermometer mb-3 ${level === 4 || level === 5 || level === 7 ? 'ml-8' : 'ml-6'}`}>{renderThermometer()}</div>;
};

export default Thermometer;
