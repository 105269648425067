import { Card } from "primereact/card";
import React from "react";
import ProfilePhoto from "./ProfilePhoto";
import { Button } from "primereact/button";
import {Team} from "../../lib/model/backend-types";
import {useNavigate} from "react-router-dom";

interface Props {
  name: string;
  teams: Team[];
}

const ProfileCard = (props: Props) => {
    const navigate = useNavigate();

    return (
    <Card header={<ProfilePhoto />} className="border-round-xl mb-2">
      <div className="flex flex-column align-items-center">
        <h2 className="-mt-2">{props.name}</h2>
          {props.teams.map(team => (
              <div key={team.name} className="flex flex-column align-items-center">
                  <Button
                  className="-mt-3 mb-1"
                  label={team.name}
                  text
                  pt={{
                      label: {style: {fontWeight: 600}},
                  }}
                  onClick={() => navigate("/roster")}
                  />
                  <h4 className="-mt-1">Role: Coach</h4>
              </div>
          ))}

      </div>
    </Card>
  );
};

export default ProfileCard;
