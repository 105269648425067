import React, { useEffect, useState} from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from "react-router-dom";
import FeaturePanel from './components/FeaturePanel';
import RevealOnScroll from './components/RevealOnScroll';

const Home: React.FC = () => {
  const navigate = useNavigate();

  const goToSignUp = async () => {
    navigate("/sign-up");
  };

  const goToSignIn = async () => {
    navigate("/sign-in");
  };

  document.addEventListener("DOMContentLoaded", () => {
    const footer = document.querySelector(".app-footer") as HTMLElement;
  
    window.addEventListener("scroll", () => {
      const isAtBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight;
  
      footer.classList.toggle("fixed-bottom", isAtBottom);
    });
  });

  const [revealedElements, setRevealedElements] = useState<HTMLElement[]>([]);

  useEffect(() => {
    function handleScroll() {
      const windowHeight = window.innerHeight;
      const newRevealedElements: HTMLElement[] = [];

      document.querySelectorAll('.reveal').forEach((element) => {
        const elementTop = element.getBoundingClientRect().top;
        const elementVisible = 200;

        if (elementTop < windowHeight - elementVisible) {
          newRevealedElements.push(element as HTMLElement);
        }
      });

      setRevealedElements(newRevealedElements);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  return (
    <div className="app">
        <header className="app-header">
        <img src="/logo-light.svg" alt="SG Logo" className="header-logo" />
        <h1>Select Swim Genius for fast results</h1>
        <div className="header-buttons">
            <Button label="Sign Up" className="mr-5" onClick={goToSignUp}/>
            <Button label="Sign In" className="header-button-yellow" onClick={goToSignIn}/>
        </div>
        </header>
        <div className="app-description">
            <div className="content-container">
                <p className="app-description-title">One stop application from organizing meets to delivering real time results</p>
                <p className="app-description-subtitle">Now you can manage your swim meet & team with one system. Paper, pens and all manual processes are gone!</p>
                <Button label="Get Swim Genius" className="app-description-button-yellow w-4" onClick={goToSignUp}/>
            </div>
            <div className="image-container">
            <img src="/roster_mobile.jpg" alt="Image 2" className="mobile-results-image" />
                <img src="/roster.jpg" alt="Image 1" className="results-image" />
            </div>
        </div>
        <div className="main-home">
          <div className="panel-container"> 
          <RevealOnScroll className="fade-left">
              <FeaturePanel
                  title="With Swim Genius you can access results online on any device"
                  descriptions={[]}
                  imageSrc="/results_mobile.png" 
                  imageSrc2="/results.jpg"
                  isLast={false}
                  isImageLeft={true}
                  />
            </RevealOnScroll >
            <RevealOnScroll className="fade-right">
              <FeaturePanel
              title="Manage all aspects of your team in one location"
              descriptions={["Manage your roster", "Schedule your meets online", "Distribute your results via the Swim Genius App", "Seed your athletes by best times"]}
              imageSrc="/roster.jpg"
              isLast={false}
              isImageLeft={false}
              />
            </RevealOnScroll >
            <RevealOnScroll className="fade-left">
              <FeaturePanel
              title="Set up and utilize one tool for any league"
              descriptions={["Summer swim league", "High School", "NCAA", "USA", "YMCA"]}
              imageSrc="/schedule.png"
              isLast={false}
              isImageLeft={true}
              />
            </RevealOnScroll >
            <RevealOnScroll className="fade-right">
              <FeaturePanel
              title="Organize your meets with ease through Swim Genius"
              descriptions={[]}
              imageSrc="/meet-ops.png"
              isLast={true}
              isImageLeft={false}
              />
            </RevealOnScroll >
          </div>
        </div>
        <footer className="app-footer">
            <p>&copy; 2023 Swim Genius. All rights reserved.</p>
        </footer>
    </div>
  );
};

export default Home;
