import React from "react";
import { Card } from "primereact/card";
import SigninDialog from "./SigninDialog";
const SigninPage = () => {
  return (
    <div className="flex">
      <div className="w-6 p-image">
        <img
          src="/login-cropped.png"
          alt="image"
          width={0}
          height={0}
          sizes="100vw"
          style={{ width: "100%", height: "auto" }}
        ></img>
      </div>
      <div className="w-6">
        <Card className="h-screen">
          <SigninDialog />
        </Card>
      </div>
    </div>
  );
};

export default SigninPage;
