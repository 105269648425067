/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * SG Backend
 * Swim Genius backend
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AddTeamReq,
  ErrorMessage,
  UserProfile
} from '../model/backend-types'
import { UseSGMutator } from '../../SGMutator';
import type { ErrorType } from '../../SGMutator';



// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


export const putProfiles = (
    userProfile: UserProfile,
 options?: SecondParameter<typeof UseSGMutator>,) => {
      
      
      return UseSGMutator<UserProfile>(
      {url: `/profiles`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: userProfile
    },
      options);
    }
  


export const getPutProfilesMutationOptions = <TError = ErrorType<ErrorMessage>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putProfiles>>, TError,{data: UserProfile}, TContext>, request?: SecondParameter<typeof UseSGMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof putProfiles>>, TError,{data: UserProfile}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putProfiles>>, {data: UserProfile}> = (props) => {
          const {data} = props ?? {};

          return  putProfiles(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PutProfilesMutationResult = NonNullable<Awaited<ReturnType<typeof putProfiles>>>
    export type PutProfilesMutationBody = UserProfile
    export type PutProfilesMutationError = ErrorType<ErrorMessage>

    export const usePutProfiles = <TError = ErrorType<ErrorMessage>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putProfiles>>, TError,{data: UserProfile}, TContext>, request?: SecondParameter<typeof UseSGMutator>}
) => {

      const mutationOptions = getPutProfilesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Creates a User profile
 */
export const postProfiles = (
    userProfile: UserProfile,
 options?: SecondParameter<typeof UseSGMutator>,) => {
      
      
      return UseSGMutator<UserProfile>(
      {url: `/profiles`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: userProfile
    },
      options);
    }
  


export const getPostProfilesMutationOptions = <TError = ErrorType<ErrorMessage>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProfiles>>, TError,{data: UserProfile}, TContext>, request?: SecondParameter<typeof UseSGMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof postProfiles>>, TError,{data: UserProfile}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProfiles>>, {data: UserProfile}> = (props) => {
          const {data} = props ?? {};

          return  postProfiles(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostProfilesMutationResult = NonNullable<Awaited<ReturnType<typeof postProfiles>>>
    export type PostProfilesMutationBody = UserProfile
    export type PostProfilesMutationError = ErrorType<ErrorMessage>

    export const usePostProfiles = <TError = ErrorType<ErrorMessage>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProfiles>>, TError,{data: UserProfile}, TContext>, request?: SecondParameter<typeof UseSGMutator>}
) => {

      const mutationOptions = getPostProfilesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getProfilesUsername = (
    username: string,
 options?: SecondParameter<typeof UseSGMutator>,signal?: AbortSignal
) => {
      
      
      return UseSGMutator<UserProfile>(
      {url: `/profiles/${username}`, method: 'get', signal
    },
      options);
    }
  

export const getGetProfilesUsernameQueryKey = (username: string,) => {
    
    return [`/profiles/${username}`] as const;
    }

    
export const getGetProfilesUsernameQueryOptions = <TData = Awaited<ReturnType<typeof getProfilesUsername>>, TError = ErrorType<ErrorMessage>>(username: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProfilesUsername>>, TError, TData>>, request?: SecondParameter<typeof UseSGMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProfilesUsernameQueryKey(username);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProfilesUsername>>> = ({ signal }) => getProfilesUsername(username, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(username), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProfilesUsername>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProfilesUsernameQueryResult = NonNullable<Awaited<ReturnType<typeof getProfilesUsername>>>
export type GetProfilesUsernameQueryError = ErrorType<ErrorMessage>

export const useGetProfilesUsername = <TData = Awaited<ReturnType<typeof getProfilesUsername>>, TError = ErrorType<ErrorMessage>>(
 username: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProfilesUsername>>, TError, TData>>, request?: SecondParameter<typeof UseSGMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProfilesUsernameQueryOptions(username,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const postProfilesUsernameAddTeam = (
    username: string,
    addTeamReq: AddTeamReq,
 options?: SecondParameter<typeof UseSGMutator>,) => {
      
      
      return UseSGMutator<unknown>(
      {url: `/profiles/${username}/add-team`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: addTeamReq
    },
      options);
    }
  


export const getPostProfilesUsernameAddTeamMutationOptions = <TError = ErrorType<ErrorMessage>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProfilesUsernameAddTeam>>, TError,{username: string;data: AddTeamReq}, TContext>, request?: SecondParameter<typeof UseSGMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof postProfilesUsernameAddTeam>>, TError,{username: string;data: AddTeamReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProfilesUsernameAddTeam>>, {username: string;data: AddTeamReq}> = (props) => {
          const {username,data} = props ?? {};

          return  postProfilesUsernameAddTeam(username,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostProfilesUsernameAddTeamMutationResult = NonNullable<Awaited<ReturnType<typeof postProfilesUsernameAddTeam>>>
    export type PostProfilesUsernameAddTeamMutationBody = AddTeamReq
    export type PostProfilesUsernameAddTeamMutationError = ErrorType<ErrorMessage>

    export const usePostProfilesUsernameAddTeam = <TError = ErrorType<ErrorMessage>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProfilesUsernameAddTeam>>, TError,{username: string;data: AddTeamReq}, TContext>, request?: SecondParameter<typeof UseSGMutator>}
) => {

      const mutationOptions = getPostProfilesUsernameAddTeamMutationOptions(options);

      return useMutation(mutationOptions);
    }
    