import styled from "@emotion/styled";
import React from "react";
import { Typography } from "./Typography";

interface SidebarHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  rtl: boolean;
}

const StyledSidebarHeader = styled.div`
  height: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 0 20px;

  > div {
    width: 100%;
    overflow: hidden;
  }
`;

export const SidebarHeader: React.FC<SidebarHeaderProps> = ({
  children,
  rtl,
  onClick,
  ...rest
}) => {
  return (
    <StyledSidebarHeader {...rest}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={"SG-logo-light.svg"}
          alt={"placeholder"}
          width={40}
          height={40}
          style={{ cursor: "pointer" }}
          onClick={onClick}
        />
        <Typography
          variant="subtitle1"
          fontWeight={700}
          color="#FFFFFF"
          style={{ marginLeft: "20px" }}
        >
          Swim Genius
        </Typography>
      </div>
    </StyledSidebarHeader>
  );
};
