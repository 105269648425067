import React, { useState } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { Card } from 'primereact/card';

interface FeesProps {
    title: string;
    individualPrelimFee: number;
    individualFinalFee: number;
    relayPrelimFee: number;
    relayFinalFee: number;
    facilityFee: number;
    athleteFee: number;
    teamFee: number;
    onNumberInputChange: (field: string, value: number | null) => void;
  }

const Fees: React.FC<FeesProps> = ({title,
    individualPrelimFee,
    individualFinalFee,
    relayPrelimFee,
    relayFinalFee,
    facilityFee,
    athleteFee,
    teamFee,
    onNumberInputChange,
}) => {

  return (
    <Card title={title} className="p-card">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p style={{marginTop: '0px'}}>Individual fees:</p>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: '1', marginRight: '1rem' }}>
            <span className="p-float-label">
                <InputNumber id="individualPrelimFee" style={{ width: '100%' }}  value={individualPrelimFee === undefined ? null : individualPrelimFee} onValueChange={(e) => onNumberInputChange('individualPrelimFee', e.value!)} />
                    <label htmlFor="individualPrelimFee">Prelim fee</label>
            </span>
          </div>
          <div style={{ flex: '1'}}>
            <span className="p-float-label">
                <InputNumber id="individualFinalFee" style={{ width: '100%' }}  value={individualFinalFee === undefined ? null : individualFinalFee} onValueChange={(e) => onNumberInputChange('individualFinalFee', e.value!)} />
                    <label htmlFor="individualFinalFee">Final fee</label>
            </span>
          </div>
        </div>
        <p>Relay fees:</p>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: '1', marginRight: '1rem' }}>
            <span className="p-float-label">
                <InputNumber id="relayPrelimFee" style={{ width: '100%' }}  value={relayPrelimFee === undefined ? null : relayPrelimFee} onValueChange={(e) => onNumberInputChange('relayPrelimFee', e.value!)} />
                    <label htmlFor="relayPrelimFee">Prelim fee</label>
            </span>
          </div>
          <div style={{ flex: '1' }}>
            <span className="p-float-label">
                <InputNumber id="relayFinalFee" style={{ width: '100%' }}  value={relayFinalFee === undefined ? null : relayFinalFee} onValueChange={(e) => onNumberInputChange('relayFinalFee', e.value!)} />
                    <label htmlFor="relayFinalFee">Final fee</label>
            </span>
          </div>
        </div>
        <p>Additional fees:</p>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: '1', marginRight: '1rem' }}>
            <span className="p-float-label">
                <InputNumber id="facilityFee" style={{ width: '100%' }}  value={facilityFee === undefined ? null : facilityFee} onValueChange={(e) => onNumberInputChange('facilityFee', e.value!)} />
                    <label htmlFor="facilityFee">Facility fee</label>
            </span>
          </div>

          <div style={{ flex: '1', marginRight: '1rem' }}>
            <span className="p-float-label">
                <InputNumber id="athleteFee" style={{ width: '100%' }}  value={athleteFee === undefined ? null : athleteFee} onValueChange={(e) => onNumberInputChange('athleteFee', e.value!)} />
                    <label htmlFor="athleteFee">Athlete fee</label>
            </span>
          </div>
          <div style={{ flex: '1' }}>
            <span className="p-float-label">
                <InputNumber id="teamFee" style={{ width: '100%' }}  value={teamFee === undefined ? null : teamFee} onValueChange={(e) => onNumberInputChange('teamFee', e.value!)} />
                    <label htmlFor="teamFee">Team fee</label>
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Fees;