import { Avatar } from "primereact/avatar";
import { Menu } from "primereact/menu";
import { useRef } from "react";
import Swimmer from "../lib/icons/Swimmer";
import { MenuItem } from "primereact/menuitem";
import Profile from "../lib/icons/Profile";
import Settings from "../lib/icons/Settings";
import Logout from "../lib/icons/Logout";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";

const SGAvatar = () => {
  const menuRight = useRef<any>(null);
  const navigate = useNavigate();
  const { logout } = useAuth();

  const items = [
    {
      label: "Coach Profile",
      icon: () => <Swimmer />,
      command: () => navigate("/profile"),
    },
    {
      label: "Team profile",
      icon: () => <Profile />,
      command: () => navigate("/profile/team"),
    },
    { label: "Settings", icon: () => <Settings /> },
    { label: "Log out ", icon: () => <Logout />, command: () => logout() },
  ] as MenuItem[];
  return (
    <>
      <Menu model={items} popup ref={menuRight} popupAlignment="right" />
      <Avatar
        className="p-avatar-menu"
        shape="circle"
        image="jason.png"
        size="xlarge"
        color="white"
        onClick={(event) => {
          if (menuRight.current !== null) {
            menuRight.current.toggle(event);
          }
        }}
        aria-controls="popup_menu_right"
        aria-haspopup
      />
    </>
  );
};

export default SGAvatar;
